const systemTooltips = {
    'enAanvullendeVarianten_officieel': 'Op het Engels gebaseerde schrijfwijze die als standaard wordt gebruikt in officiële contexten',
    'wikipediaNL': 'Schrijfwijze volgens de regels zoals gehanteerd in de Nederlandstalige Wikipedia. In andere Wikipedia-edities kunnen uiteraard andere schrijfwijzen voorkomen',
    'deAanvullendeVarianten_populairDEDDR': 'Gebruikelijke schrijfwijze in de DDR',
    'kbNL': 'Schrijfwijze gehanteerd door de Koninklijke Bibliotheek in Den Haag',
    'LOC': 'Schrijfwijze gehanteerd door de Library of Congress in de VS',
    'BLpre1975': 'Schrijfwijze gehanteerd door de British Library (tot 1975)',
    'BLpost1975': 'Schrijfwijze gehanteerd door de British Library (vanaf 1975)',
    'wetenschappelijk': 'De zogeheten wetenschappelijke transliteratie, met name gehanteerd op het Europese vasteland',
    'wetenschappelijkEngels': 'De ALA-LC-transliteratie, met name gehanteerd door wetenschappers in de Angelsaksische wereld',
    'wetenschappelijkEngelsSimpel': 'Identiek aan de ALA-LC-transliteratie, maar dan zonder diakritische tekens. Vooral gebruikt wanneer de mogelijkheid van automatische retransliteratie naar het cyrillisch niet noodzakelijk is.',
    'bgnPcgn': 'Officiële schrijfwijze in Britse en Amerikaanse geografische naslagwerken',
    'verkeersborden': 'Officiële schrijfwijze op verkeersborden',
    'stabi': 'Schrijfwijze gehanteerd door de Staatsbibliothek in Berlijn',
    'ISO1995': 'Schrijfwijze vastgelegd in de internationale norm ISO 9:1995. In Rusland en een aantal ex-Sovjet-republieken overgenomen als systeem A in de norm GOST 7.79-2000.',
    'GOST2000B': 'Schrijfwijze volgens systeem B van de norm GOST 7.79-2000, zoals geldig in Rusland en een aantal ex-Sovjet-republieken',
    'paspoort2013': 'Standaardschrijfwijze in paspoorten (sinds 2013) en rijbewijzen (sinds 2016). Ook andere schrijfwijzen kunnen voorkomen, met name indien overgenomen uit eerdere identiteitsdocumenten.',
    'paspoort2010': 'Standaardschrijfwijze in paspoorten uitgegeven tussen 2010 en 2013',
    'paspoort2007': 'Standaardschrijfwijze in paspoorten uitgegeven tussen 2007 en 2010',
    'paspoort2004': 'Standaardschrijfwijze in paspoorten uitgegeven tussen 2004 en 2007',
    'paspoort1997': 'Standaardschrijfwijze in paspoorten uitgegeven tussen 1997 en 2010',
    'paspoort1995': 'Standaardschrijfwijze in paspoorten uitgegeven tussen 2005 en 2004',
    'paspoortUssr': 'Standaardschrijfwijze in paspoorten uitgegeven in de Sovjet-Unie',
    'rijbewijs2000': 'Standaardschrijfwijze in rijbewijzen tussen 2000 en 2016',
    'vn': 'Schrijfwijze zoals voor aardrijkskundige namen aanbevolen door de Verenigde Naties',
    'Officieel gebruik (Verenigde Naties)': 'Beirut 2007 System - UNGEGN (United Nations Group of Experts on Geographical Names)',
    'Officieel gebruik (Frankrijk)': 'IGN Système 1973 (Institut Géographique National)',
    'Officieel gebruik (Groot-Brittannië & Verenigde Staten)': 'BGN/PCGN 1956 System - The United States Board on Geographic Names (BGN) & The Permanent Committee on Georaphical Names for British Official Use (PCGN)',
    'Officieel gebruik (Jordanië)': 'RJGC System (Royal Jordanian Geographic Centre)',
    'Officieel gebruik (Egypte & Soedan)': 'Survey of Egypt System',
    'Wetenschap (Angelsaksisch)': 'The Encyclopaedia of Islam',
    'Wetenschap (Europa)': 'The Encyclopedia of Arabic Language and Linguistics',
    'Wetenschap (Europa & Verenigde Staten)': 'Hans Wehr Transliteration System',
    'Wetenschap (Nederlands, vereenvoudigd)': 'Leemhuis 1982 Vereenvoudigd (uit: Rodinson, Maxime - Mohammed: een biografie)',
    'DMG': 'Deutsche Morgenländische Gesellschaft (DIN 31635 1982, Deutsches Institut für Normung)',
    'British Standards': 'BS 4280 (The British Standards Institution)',
    'Internationale Burgerluchtvaartorganisatie': 'Internationale Burgerluchtvaartorganisatie - transliteratie voor Machine Readable Zone op het paspoort',
    'Verenigde Staten': 'ALA-LC (American Library Association & The Library of Congress)',
    'ISO': 'ISO 233: 1984 (Organisation Internationale de Normalisation)',
}

export default function getSystemTooltip(system) {
    return systemTooltips[system] || undefined;
}
