import {
  Alert,
  AlertTitle,
} from '@material-ui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Icon,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';

import React from 'react';
import {RotateLeftOutlined} from '@material-ui/icons';
import infoTooltip from './../utils/infoTooltip';
import { makeStyles } from '@material-ui/core/styles';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formElements: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  dropdownSelection: {
    minWidth: '25%',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cookieNotice: {
    marginTop: '1em',
    marginBottom: 0,
  },
  tooltipButton: {
    marginBottom: '6px',
  },
  tooltipIcon: {
    fontSize: '0.9rem',
    color: '#2196f3',
  },
  description: {
    textAlign: 'left',
  },
  settingsSwitch: {
    marginRight: 0,
  },
  fakeLink: {
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  warningList: {
    margin: 0,
    padding: 0,
  },
  warningListItem: {
    padding: 0,
  },
  quickTranscription: {
    marginRight: '16px',
  }
}));

export default function QueryBox(props) {
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [cookies, setCookie] = useCookies(['transcriptor-settings']);
  
  // State hooks
  let [arabicCountry, setArabicCountry] = React.useState('unknown');
  let [showArabicWorld, toggleArabicDisplay] = React.useState(false);
  const [openSettings, setOpen] = React.useState(false);
  const [openInfoText, setOpenInfoText] = React.useState(false);
  const defaultUserInput = {
    type: '',
    lang: '',
    country: '',
    searchString: '',
    comments: [],
  }
  const [userInput, setUserInput] = React.useState(defaultUserInput);
  const [userText, setUserText] = React.useState('');
  const [selectedTermType, setSelectedTermType] = React.useState(null);
  const [selectedCountry, setSelectedCountry] = React.useState(null);

  // Settings, after checking for cookies
  const defaultSettings = {
    ukTolerantVowelCollision: false,
    ukNoSimplification: false,
    ukAllowCorrections: true,
    ruTolerantVowelCollision: false,
    ruAllowCorrections: true,
    ruOnCrimea: true,
    ruInDNRLNR: false,
    arAlElAssimilation: false,
    arWordEndingH: false,
    arRemoveAlElForGeo: true,
    arRemoveAlElForPersons: false,
    arTaMarbutaConstructState: false,
    arAlElCapitalized: false,
  }
  const savedSettings = cookies.transcriptorSettings;
  const [settings, setSettings] = React.useState(savedSettings ? savedSettings : defaultSettings);

  const arabicCountries = [{ country: 'Algerije', code: 'dz' }, { country: 'Marokko', code: 'ma' }, { country: 'Tunesië', code: 'tn' }, { country: 'Libië', code: 'ly' }, { country: 'Egypte', code: 'eg' }, { country: 'Soedan', code: 'sd' }, { country: 'Palestina', code: 'ps' }, { country: 'Libanon', code: 'lb' }, { country: 'Syrië', code: 'sy' }, { country: 'Irak', code: 'iq' }, { country: 'Koeweit', code: 'kw' }, { country: 'Jordanië', code: 'jo' }, { country: 'Saoedi-Arabië', code: 'sa' }, { country: 'Jemen', code: 'ye' }, { country: 'Verenigde Arabische Emiraten', code: 'ae' }, { country: 'Oman', code: 'om' }, { country: 'Bahrein', code: 'bh' }, { country: 'Qatar', code: 'qa' }, { country: 'Mauritanië', code: 'mr' }]
  const changeLanguage = (e) => {
    const isArabic = e.target.value === 'ar';

    // Show dropdown if the language is Arabic
    toggleArabicDisplay(isArabic);

    // Reset state for Arabic countries
    if (isArabic) {
      setArabicCountry('unknown');
      setUserInput({ ...userInput, lang: 'ar', country: 'unknown' });
    }
  };

  const handleArabicSelection = event => {
    setArabicCountry(event.target.value);
    setUserInput({ ...userInput, country: event.target.value });
  };
  const handleClickOpenSettings = () => {
    setOpen(true);
  };
  const handleCloseSettings = () => {
    setOpen(false);
  };
  const saveSettingsInCookies = () => {
    // Close the modal
    handleCloseSettings();

    // Apply new settings immediately by restarting the transcription if there are already results
    if (Object.keys(props.process.data.results).length > 0) {
      props.startTranscription(userInput, settings, JSON.parse(JSON.stringify(props.process)));
    }

    // Save current settings in cookies for the next year
    setCookie('transcriptorSettings', settings, {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      sameSite: 'strict',
    });
  }
  const handleSettingsChange = (event) => {
    setSettings({ ...settings, [event.target.name]: event.target.checked });
  };
  const handleUserInputChange = (event) => {
    if (event.target.name === 'searchString' && selectedCountry && selectedCountry !== 'ar') {
      event.target.value = event.target.value.replace(/́/g, '');
    }
    
    // eslint-disable-next-line default-case
    switch (event.target.name) {
      case 'searchString':
        setUserText(event.target.value);
        break;
      case 'type':
        setSelectedTermType(event.target.value);
        break;
      case 'lang':
        setSelectedCountry(event.target.value);
        break;
    }

    if (event.target.name === 'lang') {
      if (event.target.value !== 'ar') {
        setUserInput({
          ...userInput,
          country: event.target.value,
          lang: event.target.value
        });
      } else {
        setUserInput({
          ...userInput, 
          country: '', 
          lang: event.target.value 
        });
      }
    } else {
      setUserInput({ ...userInput, [event.target.name]: event.target.value });
    }
  };
  const handleClickOpenInfoText = () => {
    setOpenInfoText(true);
  };
  const handleCloseInfoText = () => {
    setOpenInfoText(false);
  };

  const startTranscription = () => {
    props.startTranscription(userInput, settings);
  }

  const startQuickTranscription = () => {
    props.startTranscription(userInput, {
      ...settings, 
      ...{
        skipWikidata: true,
      }
    });
  }

  const listenForEnter = (event) => {
    if (event.keyCode === 13 && !props.loading) {
      startTranscription();
    };
  }

  const resetForm = () => {
    // Reset internal representation
    setUserInput(defaultUserInput);

    // Reset interface
    setArabicCountry('unknown');
    toggleArabicDisplay(false);
    setUserText('');
    setSelectedCountry(null);
    setSelectedTermType(null);
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="body1" className={classes.description} gutterBottom>
          {/* TODO: ADD ARABIC BACK */}
          De Transcriptor geeft Russische en Oekraïense persoonsnamen, aardrijkskundige aanduidingen en woorden weer in de schrijfwijze die voor het Nederlandse taalgebied gebruikelijk of aanbevolen is.
          <IconButton size="small" className={classes.tooltipButton} onClick={handleClickOpenInfoText}>
            <Icon fontSize="small" className={classes.tooltipIcon} style={{'color': 'inherit'}}>help</Icon>
          </IconButton>
        </Typography>

        <Divider light />

        <div className={classes.formElements}>
          <FormControl component="fieldset">
            <Typography gutterBottom variant="h6">
              Van wat voor term zoek je de Nederlandse schrijfwijze?
              
              {infoTooltip("Ondersteund worden persoonsnamen, aardrijkskundige aanduidingen (plaatsnamen, waternamen, bergen, etc.) en – in beperkte mate – overige woorden.") }
            </Typography>
            <RadioGroup aria-label="position" name="position" row className={classes.centered} onChange={handleUserInputChange} value={selectedTermType}>
              <FormControlLabel
                value="person"
                control={<Radio color="primary" />}
                label="Persoon"
                labelPlacement="bottom"
                name="type"
              />
              <FormControlLabel
                value="geo"
                control={<Radio color="primary" />}
                label="Plaats"
                labelPlacement="bottom"
                name="type"
              />
              <FormControlLabel
                value="misc"
                control={<Radio color="primary" />}
                label="Anders"
                labelPlacement="bottom"
                name="type"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className={classes.formElements}>
          <FormControl component="fieldset" onChange={changeLanguage}>
            <Typography gutterBottom variant="h6" className={!Boolean(userInput.type) ? 'Mui-disabled' : ''}>
              In welk land of welke regio?

              {infoTooltip("Hoe specifieker je hier een land of regio aangeeft, hoe beter we kunnen proberen de invoer te identificeren in onze bronnen.")}
            </Typography>
            <RadioGroup aria-label="position" name="position" row className={classes.centered} onChange={handleUserInputChange} value={selectedCountry}>
              <FormControlLabel
                value="ru"
                control={<Radio color="primary" />}
                label="Rusland"
                labelPlacement="bottom"
                name="lang"
                disabled={!Boolean(userInput.type)}
              />
              <FormControlLabel
                value="uk"
                control={<Radio color="primary" />}
                label="Oekraïne"
                labelPlacement="bottom"
                name="lang"
                disabled={!Boolean(userInput.type)}
              />
              {/* TODO: ADD BACK */}
              {/* <FormControlLabel
                value="ar"
                control={<Radio color="primary"/>}
                label="Arabischtalige wereld"
                labelPlacement="bottom"
                name="lang"
                disabled={!Boolean(userInput.type)}
              /> */}
              <FormControlLabel
                value="unknown"
                control={<Radio color="primary" />}
                label="Onbekend"
                labelPlacement="bottom"
                name="lang"
                disabled={!Boolean(userInput.type)}
              />
            </RadioGroup>
          </FormControl>
        </div>

        { showArabicWorld && 
          <div className={classes.formElements}>
            <FormControl className={`${classes.formControl} ${classes.dropdownSelection}`}>
              <InputLabel ref={inputLabel} id="arabic-country-selection-label">
                Arabischtalig land
              </InputLabel>
              <Select id="arabic-country-selection" labelId="arabic-country-selection-label" value={arabicCountry} onChange={handleArabicSelection}>
                {
                  arabicCountries
                    .sort(function(a, b) {
                      var textA = a.country;
                      var textB = b.country;
                      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    })
                    .map((country, i) => <MenuItem value={country.code} key={i}>{country.country}</MenuItem>)
                }
                <MenuItem value="unknown">Onbekend</MenuItem>
              </Select>
              <FormHelperText>Als je niet zeker weet uit welk Arabisch land jouw term komt, selecteer dan ‘Onbekend’.</FormHelperText>
            </FormControl>
          </div>
        }

        <Divider light />

        <div className={`${classes.formElements} ${classes.searchContainer}`}>
          <Tooltip title="Invoer is mogelijk in het oorspronkelijke of in het Latijnse schrift. Invoer in het oorspronkelijke schrift leidt tot de beste resultaten." placement="bottom-start">
            <TextField
              id="outlined-full-width"
              label='Voer de naam in die je wilt omzetten'
              style={{ margin: 8 }}
              placeholder="Москва | Nizhny Novgorod | Київ | Zaporizhia" 
              // TODO: ADD BACK  | مكة | Fallujah
              fullWidth
              margin="normal"
              variant="outlined"
              autoComplete="off"
              autoCorrect="off"
              spellCheck="false"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ maxLength: 250 }}
              className="queryText"
              name="searchString"
              onChange={handleUserInputChange}
              onKeyDown={listenForEnter}
              disabled={!Boolean(userInput.country) || props.loading}
              value={userText}
            />
          </Tooltip>
          <IconButton aria-label="settings" onClick={handleClickOpenSettings}>
            <Icon>settings</Icon>
          </IconButton>
        </div>

        <div>
          {props.loading ? (
            <>
              <LinearProgress />
              <Typography variant="caption" display="block">
                {props.loadingText}
              </Typography>
            </>
          ) : (
            <>
              <Tooltip title="Met deze knop kan je de invoer wissen om compleet opnieuw te beginnen met zoeken. Wijzigingen kunnen ook direct worden gemaakt in het formulier voor een nieuwe zoekopdracht, daarvoor is het compleet wissen van de huidige invoer niet nodig." placement="bottom-start">
                <IconButton onClick={resetForm}>
                  <RotateLeftOutlined/>
                </IconButton>
              </Tooltip>

              <Tooltip title="Met deze keuze wordt het zoeken in externe databases overgeslagen en wordt de invoer meteen omgezet. Dit kan de resultaten minder betrouwbaar maken." placement="bottom-start">
                <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    className={classes.quickTranscription}
                    startIcon={<Icon>translate</Icon>}
                    onClick={startQuickTranscription}
                    disabled={!Boolean(userInput.searchString)}
                  >
                  Meteen omzetten
                </Button>
              </Tooltip>

              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<Icon>search</Icon>}
                onClick={startTranscription}
                disabled={!Boolean(userInput.searchString)}
              >
                Invoer identificeren
              </Button>
              </>
            )}
        </div>
      </Paper>

      <Dialog open={openSettings} onClose={handleCloseSettings} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Transcriptie-instellingen</DialogTitle>
        <DialogContent>
          <FormGroup>
            <Typography variant="subtitle1" gutterBottom>
              Instellingen voor Rusland
            </Typography>
            <div>
              <FormControlLabel
                className={classes.settingsSwitch}
                control={<Switch color="primary" checked={settings.ruTolerantVowelCollision} onChange={handleSettingsChange} name="ruTolerantVowelCollision" />}
                label="Minder strikte omgang met botsende klinkers"
              />
              {infoTooltip('Namen als Михаил en Чаадаев worden in het Nederlands weergegeven als Michaïl en Tsjaädajev. De tweede klinker krijgt een trema omdat de twee klinkers tot verschillende lettergrepen behoren (conform de spellingregels bij klinkerbotsing). Als deze optie wordt ingeschakeld, worden dergelijke trema’s tot een minimum beperkt, wat leidt tot Michail en Tsjaadajev.')}
            </div>
            <div>
              <FormControlLabel
                className={classes.settingsSwitch}
                control={<Switch color="primary" checked={settings.ruAllowCorrections} onChange={handleSettingsChange} name="ruAllowCorrections" />}
                label="Correcties toestaan op de transcriptie"
              />
              {infoTooltip('Sommige fouten in de invoer kunnen bij het omzetten worden gedetecteerd en gecorrigeerd. Het gaat dan vooral de letter ё in namen als Киселёв en Артёмки (Kiseljov, Artjomki), die in het Russisch in de regel worden geschreven als Киселев en Артемки (Kiselev, Artemki). Als deze optie wordt uitgeschakeld, blijft deze correctie achterwege.')}
            </div>

            <Divider />

            <Typography variant="subtitle1" gutterBottom>
              Instellingen voor Oekraïne
            </Typography>
            <div>
              <FormControlLabel
                className={classes.settingsSwitch}
                control={<Switch color="primary" checked={settings.ukTolerantVowelCollision} onChange={handleSettingsChange} name="ukTolerantVowelCollision" />}
                label="Minder strikte omgang met botsende klinkers"
              />
              {infoTooltip('Namen als Новоіванівка en Заайдарівка worden in het Nederlands weergegeven als Novoïvanivka en Zaäjdarivka. De tweede klinker krijgt een trema omdat de twee klinkers tot verschillende lettergrepen behoren (conform de spellingregels bij klinkerbotsing). Als deze optie wordt ingeschakeld, worden dergelijke trema’s tot een minimum beperkt, wat leidt tot Novoivanivka en Zaajdarivka.')}
            </div>
            <div>
              <FormControlLabel
                className={classes.settingsSwitch}
                control={<Switch color="primary" checked={settings.ukNoSimplification} onChange={handleSettingsChange} name="ukNoSimplification" />}
                label="Complexe medeklinkercombinaties niet versimpelen"
              />
              {infoTooltip('Een naam als Запоріжжя wordt in het Nederlands weergegeven als Zaporizja. De dubbele medeklinker жж (zjzj) wordt daarbij versimpeld tot een enkele (zj). Als deze optie wordt ingeschakeld, blijft deze versimpeling achterwege, wat leidt tot Zaporizjzja.')}
            </div>
            <div>
              <FormControlLabel
                className={classes.settingsSwitch}
                control={<Switch color="primary" checked={settings.ukAllowCorrections} onChange={handleSettingsChange} name="ukAllowCorrections" />}
                label="Correcties toestaan op de transcriptie"
              />
              {infoTooltip('Sommige fouten in de invoer kunnen bij het omzetten worden gedetecteerd en gecorrigeerd. Als deze optie wordt uitgeschakeld, blijft deze correctie achterwege.')}
            </div>
            <div>
              <FormControlLabel
                className={classes.settingsSwitch}
                control={<Switch color="primary" checked={settings.ruOnCrimea} onChange={handleSettingsChange} name="ruOnCrimea" />}
                label="Voorkeur voor Russische namen op de Krim"
              />
              {infoTooltip('De Krim is een historisch Russischtalige streek in Oekraïne. Hoewel onder de huidige Oekraïense wet alleen het Oekraïens een officiële taal is, is sinds de eenzijdige annexatie van het gebied door Rusland in 2014 het Russisch eens te meer dominant. Als deze optie wordt ingeschakeld, wordt voor plaatsen op de Krim steeds eerst de Russische naam getoond.')}
            </div>
            <div>
              <FormControlLabel
                className={classes.settingsSwitch}
                control={<Switch color="primary" checked={settings.ruInDNRLNR} onChange={handleSettingsChange} name="ruInDNRLNR" />}
                label="Voorkeur voor Russische namen in de DNR/LNR"
              />
              {infoTooltip('Hoewel in heel Oekraïne alleen het Oekraïens een officiële taal is, is in het gebied van de zelfverklaarde ‘Volksrepublieken’ Donetsk en Loegansk in het oosten van het land het Russisch dominant. Als deze optie wordt ingeschakeld, wordt voor plaatsen in deze gebieden steeds eerst de Russische naam getoond.')}
            </div>
            
            {/* TODO: ADD BACK */}
            {/* <Divider/>

            <Typography variant="subtitle1" gutterBottom>
              Instellingen voor de Arabischtalige wereld
            </Typography>
            <div>
              <FormControlLabel
                className={classes.settingsSwitch}
                control={<Switch color="primary" checked={settings.arRemoveAlElForGeo} onChange={handleSettingsChange} name="arRemoveAlElForGeo" />}
                label="Weglaten lidwoord al-/el- bij plaatsnamen"
              />
              {infoTooltip('Het lidwoord al-/el- wordt in transcripties van Arabische aardrijkskundige namen vaak weggelaten. Dat gebeurt vooral bij namen van landen en van bekendere steden. Wanneer je deze functie aanzet, wordt ‘Al-Sudan’ bijvoorbeeld ‘Sudan’.')}
            </div>
            <div>
              <FormControlLabel
                className={classes.settingsSwitch}
                control={<Switch color="primary" checked={settings.arRemoveAlElForPersons} onChange={handleSettingsChange} name="arRemoveAlElForPersons" />}
                label="Weglaten lidwoord al-/el- bij persoonsnamen"
              />
              {infoTooltip('Het lidwoord al-/el- wordt in transcripties van Arabische persoonsnamen soms weggelaten. Dat gebeurt vooral bij bekende personen die vaak in het nieuws komen. Wanneer je deze functie aanzet, wordt ‘Al-Asad’ bijvoorbeeld ‘Asad’.')}
            </div>
            <div>
              <FormControlLabel
                className={classes.settingsSwitch}
                control={<Switch color="primary" checked={settings.arAlElAssimilation} onChange={handleSettingsChange} name="arAlElAssimilation" />}
                label="Assimilatie van het lidwoord al-/el-"
              />
              {infoTooltip('Het lidwoord al-/el- past zich in gesproken Arabisch soms aan de beginklank van het volgende woord aan. Je kunt ervoor kiezen dit in het Latijnse alfabet weer te geven. ‘Al-Sudan’ bijvoorbeeld wordt dan ‘As-Sudan’.')}
            </div>
            <div>
              <FormControlLabel
                className={classes.settingsSwitch}
                control={<Switch color="primary" checked={settings.arWordEndingH} onChange={handleSettingsChange} name="arWordEndingH" />}
                label="Weglaten woordafsluitende -h"
              />
              {infoTooltip('Namen die in het Arabisch op een korte a-klank eindigen, krijgen in Latijnse weergave vaak een extra woordafsluitende -h. Omdat dit niet leidt tot een andere uitspraak, kun je ervoor kiezen deze -h weg te laten. De naam ‘Fallujah’ bijvoorbeeld wordt dan ‘Falluja’.')}
            </div>
            <div>
              <FormControlLabel
                className={classes.settingsSwitch}
                control={<Switch color="primary" checked={settings.arTaMarbutaConstructState} onChange={handleSettingsChange} name="arTaMarbutaConstructState" />}
                label="Status constructus van woordafsluitende korte -a"
              />
              {infoTooltip("Namen die in het Arabisch op een korte a-klank eindigen, kunnen om grammaticale redenen in een ‘status constructus’ worden geplaatst als er een andere naam op volgt. ‘Hamza bin Ladin’ wordt dan bijvoorbeeld ‘Hamzat bin Ladin’. In gesproken Arabisch komt dit echter maar weinig voor. ")}
            </div>
            <div>
              <FormControlLabel
                className={classes.settingsSwitch}
                control={<Switch color="primary" checked={settings.arAlElCapitalized} onChange={handleSettingsChange} name="arAlElCapitalized" />}
                label="Laat het lidwoord al-/el- in de resultaten zien met een hoofdletter"
              />
            </div> */}

          </FormGroup>
          <DialogContentText className={classes.cookieNotice}>
            Je persoonlijke instellingen worden opgeslagen door middel van cookies. Als je jouw instellingen aanpast, ga je hiermee akkoord.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ 'justifyContent': 'space-between', 'paddingLeft': 'calc(24px - 6px)' }}>
          <Button onClick={handleCloseSettings} color="primary">
            Niet opslaan
          </Button>
          <Button onClick={saveSettingsInCookies} color="primary">
            Opslaan en toepassen bij volgende transcriptie
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openInfoText} onClose={handleCloseInfoText} aria-labelledby="form-dialog-title" maxWidth="lg">
        <DialogTitle id="form-dialog-title">Transcriptie-instellingen</DialogTitle>
        {/* TODO: RE-ADD BACK WHEN ARABIC IS IN ORDER */}
        {/* <DialogContent>
          <DialogContentText>
            Deze Transcriptor geeft Russische, Oekraïense en Arabische persoonsnamen, aardrijkskundige aanduidingen en woorden weer in de schrijfwijze die voor het Nederlandse taalgebied gebruikelijk of aanbevolen is.
          </DialogContentText>
          <DialogContentText>
            De beste resultaten worden bereikt met invoer in de originele taal en in het originele schrift (dat wil zeggen: in het cyrillisch of in het Arabisch), maar ook invoer in het Latijnse alfabet is mogelijk. De Transcriptor is voor het Arabisch getraind in het omzetten van oorspronkelijk Arabische namen. Namen in Arabischtalige landen die uit andere talen stammen – zoals het Turks en het Perzisch – worden mogelijk minder nauwkeurig omgezet. In alle gevallen zal aan de hand van externe gegevensbronnen geprobeerd worden de invoer te identificeren en te koppelen aan een bekende persoon of plaats. De belangrijkste bron van deze gegevens is het <a href="https://www.wikidata.org/">Wikidata-project</a> van de Wikimedia Foundation.
          </DialogContentText>
          <DialogContentText>
            Als er geen identificatie mogelijk is, zullen we tóch proberen om de juiste schrijfwijze in het Nederlands te bepalen. Daarvoor moet de invoer in het originele schrift zijn of in het Latijnse schrift in een taal als het Nederlands, Engels, Frans of Duits. We zullen de originele schrijfwijze dan in veel gevallen moeten reconstrueren.
          </DialogContentText>
          <DialogContentText>
            <Alert severity="warning">
              <AlertTitle>Waarschuwingen voor gebruik</AlertTitle>
              <ul className={classes.warningList}>
                <li className={classes.warningListItem}>Het systeem dat deze reconstructie maakt, is getraind met grote databestanden om een zo hoog mogelijke betrouwbaarheid te krijgen. Toch is het goed om erop te wijzen dat de gereconstrueerde vorm fout kán zijn.</li>
                <li className={classes.warningListItem}>Specifiek voor het Arabisch geldt dat in het Arabische schrift in de regel geen klinkers worden geschreven, waardoor we deze zo goed en zo kwaad als het gaat moeten zien te achterhalen.</li>
                <li className={classes.warningListItem}>Als een naam is geïdentificeerd of gereconstrueerd vervaardigt de Transcriptor automatisch verschillende weergaven van de naam voor verschillende talen, genres en gebruikssituaties. Deze transcripties zijn gebaseerd op de praktijk en op nationale en internationale standaarden.</li>
                <li className={classes.warningListItem}>Personen en plaatsen in Oekraïne zijn vaak bekend onder de officiële Oekraïense als onder hun Russische naam. De Transcriptor geeft in Oekraïne zowel de Oekraïense als de Russische naam van een persoon of plaats, ook al is alleen de Oekraïense variant officieel. Het is aan de gebruiker om een keuze te maken uit de twee aangeboden varianten.</li>
              </ul>
            </Alert>
          </DialogContentText>
          <DialogContentText>
            <Typography variant="subtitle1" gutterBottom>
              Kleine lettertjes
            </Typography>
            Deze versie van de Transcriptor is tot stand gekomen dankzij een financiële bijdrage van de Nederlandse Taalunie.
          </DialogContentText>
          <DialogContentText>
            Een eerdere versie van de Transcriptor (alleen voor Russische namen) kwam in 2017 tot stand dankzij een NWO KIEM-subsidie, en was gebouwd door Pepijn Hendriks, Martin Reynaert en Nicoline van der Sijs. Partners in deze versie waren het ANP, het Genootschap Onze Taal, de Nederlandse Taalunie, de NOS, Tilburg University, de Radboud Universiteit Nijmegen en de Vlaamse omroep VRT.
          </DialogContentText>
          <DialogContentText>
            Documentatie over deze eerdere versie is te vinden in: <a href="https://ticclops.uvt.nl/TranscriptorDocumentatieV0-01.pdf" target="_blank" rel="noopener noreferrer">Pepijn Hendriks, Martin Reynaert &amp; Nicoline van der Sijs (2016), ‘Transcriptor Documentatie versie 0.01’, Language and Speech Technology, Technical Report Series, Report Number LST-16-01.</a>
          </DialogContentText>
        </DialogContent> */}
        <DialogContent>
          <DialogContentText>
            De Transcriptor geeft Russische en Oekraïense persoonsnamen, aardrijkskundige aanduidingen en woorden weer in de schrijfwijzen zoals die gebruikelijk zijn binnen verschillende Nederlandse tekstsoorten (populair-wetenschappelijk, wetenschappelijk e.d.) en internationaal.
          </DialogContentText>
          <DialogContentText>
            De beste resultaten worden bereikt met invoer in de originele taal en in het originele schrift (dat wil zeggen: in het cyrillisch), maar ook invoer in het Latijnse alfabet is mogelijk. 
          </DialogContentText>
          <DialogContentText>
            Aan de hand van externe gegevensbronnen zal de Transcriptor proberen de invoer te identificeren en te koppelen aan een bekende persoon of plaats. De belangrijkste bron van deze gegevens is het <a href="https://www.wikidata.org/">Wikidata-project</a> van de Wikimedia Foundation.
          </DialogContentText>
          <DialogContentText>
            Als het niet lukt om de invoer te identificeren, zal de Transcriptor tóch proberen om de juiste schrijfwijze in het Nederlands te bepalen. Als de invoer niet in het originele schrift is, doet het systeem een poging de originele schrijfwijze te reconstrueren.
          </DialogContentText>
          <DialogContentText>
            Voor toelichting over de verschillende naamsvarianten in Oekraïne zie: <br/>
            <a href="https://onzetaal.nl/nieuws-en-dossiers/weblog/kiev-of-kyjiv" target="_blank" rel="noopener noreferrer">https://onzetaal.nl/nieuws-en-dossiers/weblog/kiev-of-kyjiv</a> <br/>
            <a href="https://onzetaal.nl/taaladvies/kiev-kyjiv-kyiv" target="_blank" rel="noopener noreferrer">https://onzetaal.nl/taaladvies/kiev-kyjiv-kyiv</a>
          </DialogContentText>
          <DialogContentText>
            <Alert severity="warning">
              <AlertTitle>Waarschuwingen voor gebruik</AlertTitle>
              <ul className={classes.warningList}>
                <li className={classes.warningListItem}>Het systeem dat deze reconstructie maakt, is getraind met grote databestanden om een zo hoog mogelijke betrouwbaarheid te krijgen. Toch is het goed om te beseffen dat de gereconstrueerde vorm fout kán zijn.</li>
                <li className={classes.warningListItem}>Als een naam eenmaal is geïdentificeerd of gereconstrueerd vervaardigt de Transcriptor automatisch verschillende weergaven van de naam voor verschillende talen, genres en gebruikssituaties. Deze transcripties zijn gebaseerd op de praktijk en op nationale en internationale standaarden.</li>
                <li className={classes.warningListItem}>Personen en plaatsen in Oekraïne zijn vaak zowel bekend onder de officiële Oekraïense als onder hun Russische naam. De Transcriptor geeft in Oekraïne zowel de Oekraïense als de Russische naam van een persoon of plaats, ook al is alleen de Oekraïense variant officieel. Het is aan de gebruiker om een keuze te maken uit de twee aangeboden varianten.</li>
              </ul>
            </Alert>
          </DialogContentText>
          <DialogContentText>
            <Typography variant="subtitle1" gutterBottom>
              Kleine lettertjes
            </Typography>
            <Typography variant="body2" gutterBottom>
              Deze versie van de Transcriptor is tot stand gekomen dankzij een financiële bijdrage van de Nederlandse Taalunie.
            </Typography>
          </DialogContentText>
          <DialogContentText>
            <Typography variant="body2" gutterBottom>
              Een eerdere versie van de Transcriptor (alleen voor Russische namen) kwam in 2017 tot stand dankzij een NWO KIEM-subsidie, en was gebouwd door Pepijn Hendriks, Martin Reynaert en Nicoline van der Sijs. Partners in deze versie waren het ANP, het Genootschap Onze Taal, de Nederlandse Taalunie, de NOS, Tilburg University, de Radboud Universiteit Nijmegen en de Vlaamse omroep VRT.
            </Typography>
          </DialogContentText>
          <DialogContentText>
            <Typography variant="body2" gutterBottom>
              Documentatie over deze eerdere versie is te vinden in: <a href="https://ticclops.uvt.nl/TranscriptorDocumentatieV0-01.pdf" target="_blank" rel="noopener noreferrer">Pepijn Hendriks, Martin Reynaert &amp; Nicoline van der Sijs (2016), ‘Transcriptor Documentatie versie 0.01’, Language and Speech Technology, Technical Report Series, Report Number LST-16-01.</a>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInfoText}>
            Terug
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
