import FatalError from './FatalError';

function objToMatrix(obj) {
    const matrix = Object.keys(obj).map(key => [key, obj[key]]);

    // Sort on key, which is the first index
    return matrix.sort((row1, row2) => {
        return row2[0].length - row1[0].length;
    })
}

export default function transliterateArabic(input, system, letters, exceptions, positional, priorityTable, capitalize = false) {
    let output;
    if (input) {
        output = `${input}`;
    } else {
        throw new FatalError('Helaas konden we voor deze invoer geen transcripties genereren. Probeer het opnieuw met een andere schrijfwijze.');
    }

    if (priorityTable && priorityTable[system]) {
        const priorityChars = objToMatrix(priorityTable[system]);
        
        for (let i = 0; i < priorityChars.length; i++) {
            try {
                let letterRegex = new RegExp(priorityChars[i][0], 'g');
                output = output.replace(letterRegex, capitalize && priorityChars[i][1] ? priorityChars[i][1].toUpperCase() : priorityChars[i][1]);
            } catch (e) {
                // A character needs to be escaped
                let letterRegex = new RegExp(`\\${priorityChars[i][0]}`, 'g');
                output = output.replace(letterRegex, capitalize && priorityChars[i][1] ? priorityChars[i][1].toUpperCase() : priorityChars[i][1]);
            }
        }
    }
    
    // Check for exceptions
    if (Object.keys(exceptions).includes(system)) {
        const exceptionTable = objToMatrix(exceptions[system]);

        for (let i = 0; i < exceptionTable.length; i++) {
            let exceptionRegex = new RegExp(`(^|\\s)${exceptionTable[i][0]}(?=$|\\s)`, 'gm');
            output = output.replace(exceptionRegex, `$1${capitalize && exceptionTable[i][1] ? exceptionTable[i][1].toUpperCase() : exceptionTable[i][1]}`);
        }
    }

    // Transcibe letters that are dependent on specific positional arguments
    const positionalTable = positional[system];

    Object.keys(positionalTable).forEach(key => {
        let replacer = (input, replacementBefore, replacementAfter) => input;

        switch (key) {
            case 'starting':
                replacer = (input, replacementBefore, replacementAfter) => {
                    let positionalRegex = new RegExp(`(^|\\s)(${replacementBefore.replace('?', '\\?')})`, 'gm');
                    return input.replace(positionalRegex, `$1${replacementAfter}`);
                }
                break;
            case 'ending':
                replacer = (input, replacementBefore, replacementAfter) => {
                    let positionalRegex = new RegExp(`${replacementBefore.replace('?', '\\?')}(?=$|\\s)`, 'gm');
                    return input.replace(positionalRegex, replacementAfter);
                }
                break;
            case 'middle':
                replacer = (input, replacementBefore, replacementAfter) => {
                    let positionalRegex = new RegExp(`(\\S)(${replacementBefore.replace('?', '\\?')})(?!=$|\\s)`, 'gm');
                    return input.replace(positionalRegex, `$1${replacementAfter}`);
                }
                break;
            default:
                // We do not have a good positional argument, therefore we need to prevent any changes to happen: replacer stays the same
                break;
        }

        let currentPosition = objToMatrix(positionalTable[key]);
        for (let i = 0; i < currentPosition.length; i++) {
            output = replacer(output, currentPosition[i][0], capitalize && currentPosition[i][1] ? currentPosition[i][1].toUpperCase() : currentPosition[i][1]);
        }
    })

    // Start transcribing regular letters
    const letterTable = objToMatrix(letters[system]);
    for (let i = 0; i < letterTable.length; i++) {
        try {
            let letterRegex = new RegExp(letterTable[i][0], 'g');
            output = output.replace(letterRegex, capitalize && letterTable[i][1] ? letterTable[i][1].toUpperCase() : letterTable[i][1]);
        } catch (e) {
            // A character needs to be escaped
            let letterRegex = new RegExp(`\\${letterTable[i][0]}`, 'g');
            output = output.replace(letterRegex, capitalize && letterTable[i][1] ? letterTable[i][1].toUpperCase() : letterTable[i][1]);
        }
    }

    // Remove all diacritics if they are still present and return object
    return capitalize ? output.replace(/[،-٩]+/gm, '').toLowerCase() : output.replace(/[،-٩]+/gm, '');
}
