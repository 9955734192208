import capitalizeAllWords from './capitalizeAllWords';

export default function getPreferredTranscription(lang, process) {
    const results = process.data.results[lang];

    switch (lang) {
        case 'uk':
            if (Object.keys(results).includes('Nederlands')) {
                return Object.values(results['Nederlands'])[0];
            } else {
                return results.bibliotheken.kbNL;
            }
        case 'ru':
            if (Object.keys(results).includes('Nederlands')) {
                return Object.values(results['Nederlands'])[0];
            } else {
                return results.bibliotheken.kbNL;
            }
        default:
            // Arabic countries
            const wikidataNL = process.data.externalInfo.data.nl;
            const wikidataEN = process.data.externalInfo.data.en;

            let finalOutput;
            if (wikidataNL && (wikidataNL.wikipedia || wikidataNL.wikidata)) {
                finalOutput = wikidataNL.wikipedia || wikidataNL.wikidata;
            } else if (wikidataEN && (wikidataEN.wikipedia || wikidataEN.wikidata)) {
                finalOutput = wikidataEN.wikipedia || wikidataEN.wikidata;
            } else {
                finalOutput = results.wetenschap['Wetenschap (Nederlands, vereenvoudigd)'];
            }

            // Capitalization rules
            finalOutput = capitalizeAllWords(finalOutput);
            if (!process.input.settings.arAlElCapitalized) {
                // Remove capitalization for lidwoord
                finalOutput = finalOutput.replace(/^|\W(Al|El)/g, s => s.toLowerCase());
            }
            
            return finalOutput;
    }
}