import { Alert, AlertTitle } from '@material-ui/lab';
import {
    Avatar,
    Card,
    CardHeader,
    Dialog,
    DialogContent,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Snackbar,
    Switch,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import React, { useEffect } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import ReactMarkdown from 'react-markdown';
import externalLinkIcon from './../images/externallinkicon.png';
import getDisplayName from './../utils/getDisplayName';
import getPreferredTranscription from './../utils/getPreferredTranscription';
import getSystemTooltip from './../utils/getSystemTooltip';
import infoTooltip from './../utils/infoTooltip';
import { makeStyles } from '@material-ui/core/styles';
import mapMarkerIcon from './../images/map-marker-icon.png';
import removeFatherName from './../utils/removeFatherName';
import taalunieLogo from './../images/taalunie.png';
import wikipediaLogo from './../images/wikipedia.jpg';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    transcriptionInfo: {
        display: 'flex',
    },
    transcriptionInfoContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    badge: {
        backgroundColor: '#eee',
    },
    title: {
        fontSize: 14,
    },
    cover: {
        width: 200,
        height: 'auto',
        marginLeft: '16px',
    },
    resultsSubheader: {
        marginTop: '16px',
    },
    resultsHeaderContainer: {
        paddingBottom: 0,
        alignItems: 'flex-start',
    },
    resultsHeading: {
        margin: '16px',
        marginBottom: 0,
    },
    resultsTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    comments: {
        marginTop: '8px',
    },
    origin: {
        fontWeight: 400,
    },
    originContainer: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
    },
    nameHistoryList: {
        paddingLeft: '10px',
        margin: 0,
        width: '100%',
    },
    toggles: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '8px',
    },
    fatherNameToggle: {
        display: 'flex',
        alignItems: 'center',
    },
    flexEnd: {
        justifyContent: 'flex-end',
    },
    credits: {
        marginTop: '0.35em',
    },
    externalLink: {
        position: 'relative',
        width: '40px',
        marginRight: '8px',
    },
    externalLinkLogo: {},
    externalLinkIcon: {
        position: 'absolute',
        bottom: '-5px',
        right: '-5px',
        backgroundColor: theme.palette.primary.main,
        width: '20px',
        height: '20px',
    },
    iconContainer: {
        display: 'flex',
        marginTop: '8px',
        cursor: 'pointer',
    },
    mapModal: {
        width: '80vw',
        height: '80vh',
        padding: '0 !important',
    },
    map: {
        width: '100%',
        height: '100%',
    },
    mapModalContainer: {
        maxWidth: 'initial',
    }
}));


export default function Results(props) {
    const classes = useStyles();

    const originalResults = props.process.data.results;
    const selectedCountry = props.process.input.userInput.country;
    let selectedUserLang; 
    if (['ru', 'uk'].includes(selectedCountry)) {
        if (((props.process.input.settings.ruOnCrimea && props.process.data.inhouseInfo.isCrimea) || (props.process.input.settings.ruInDNRLNR && (props.process.data.inhouseInfo.ukIsDNR || props.process.data.inhouseInfo.ukIsLNR)) || (props.process.data.languageTest.determinedLanguage === 'ru' && props.process.data.externalInfo.data[selectedCountry] && props.process.data.externalInfo.data[selectedCountry].wikidata) || (props.process.data.languageTest.determinedLanguage === 'ru' && !props.process.data.externalInfo.id)) && selectedCountry === 'uk') {
            selectedUserLang = 'ru';
        } else {
            selectedUserLang = selectedCountry;
        }
    } else {
        selectedUserLang = props.process.input.userInput.lang;
    }
    const transliterationBase = props.process.data.transliterationBase;
    const showFatherNameToggle = props.process.input.userInput.type === 'person' && ['uk', 'ru'].includes(props.process.input.userInput.country) && (props.process.data.transliterationBase[props.process.input.userInput.country] ? props.process.data.transliterationBase[props.process.input.userInput.country].split(' ').length === 3 : false);

    // Set state for all resuts
    const [allResults, setAllResults] = React.useState({});

    // Set state for selected language
    const [showedLang, setShowedLang] = React.useState('');
    
    // Set state for transcription base
    const [transcription, setTranscription] = React.useState('');
    const [origin, setOriginText] = React.useState('');
    const setOrigin = (text) => {
        const lang = props.process.input.userInput.lang;
        
        if (text === '') {
            setOriginText(text);
        } else if (lang !== 'ar') {
            const inhouseInfo = props.process.data.inhouseInfo;
            setOriginText(inhouseInfo.renamedIn2016 && (inhouseInfo.ukIsDNR || inhouseInfo.ukIsLNR) ? `${text}; lokaal ook wel ${inhouseInfo.oldNames[showedLang]}`: text);
        } else {
            // Arabic always follows the same pattern for origin: (Arabic if possible, then DMG+)
            setOriginText(`${props.process.data.externalInfo.data[lang] && (props.process.data.externalInfo.data[lang].wikipedia || props.process.data.externalInfo.data[lang].wikidata) ? `${props.process.data.externalInfo.data[lang].wikipedia || props.process.data.externalInfo.data[lang].wikidata}, ` : props.process.data.languageTest.languageValidity[lang] ? `${props.process.input.userInput.searchString}, ` : ''}${props.process.data.results.ar['internationale standaarden'].DMG}`);
        }
    } 

    // Set state for showed father's name
    const [showFatherName, setShowFatherName] = React.useState(false);

    const [showMap, setShowMap] = React.useState(false);

    useEffect(() => {
        setShowedLang(selectedUserLang);
    }, [selectedUserLang])

    useEffect(() => {
        setAllResults(JSON.parse(JSON.stringify(originalResults)));

        // Show father's name by default when user inputs father's name without wikidata match
        if (showFatherNameToggle) {
            setShowFatherName(!props.process.data.externalInfo.id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originalResults]);

    useEffect(() => {
        const resultSize = Object.keys(originalResults).length;

        // Make sure that origin and transcription are resetted when new transcription is done
        if (resultSize === 0) {
            setTranscription('');
            setOrigin('');
        }

        // Loop through all results and comments and either remove or append father's name
        if (showFatherNameToggle) {
            const currentLang = showedLang ? showedLang : selectedUserLang;
            const preferredTranscription = getPreferredTranscription(showedLang || selectedUserLang, props.process);

            if (showFatherName) {
                setAllResults(JSON.parse(JSON.stringify(originalResults)));
                setTranscription(preferredTranscription);
                setOrigin(transliterationBase[showedLang || selectedUserLang]);
            } else if (resultSize > 0) {
                // Create copy
                let placeholder = JSON.parse(JSON.stringify(originalResults));
                let transcriptions = Object.keys(placeholder).map(lang => getPreferredTranscription(lang, props.process));

                if (Object.keys(props.process.data.inhouseInfo.simplificationOutputs).length > 0) {
                    transcriptions = [...transcriptions, Object.values(props.process.data.inhouseInfo.simplificationOutputs)];
                }
                
                const vowelCollisionObj = props.process.data.inhouseInfo.vowelCollisionOutputs;
                if (Object.keys(vowelCollisionObj).length > 0) {
                    for (const key in vowelCollisionObj) {
                        transcriptions = [...transcriptions, ...Object.values(vowelCollisionObj[key])]
                    }
                }

                for (const lang in placeholder) {
                    for (const collection in placeholder[lang]) {
                        if (collection !== 'comments') {
                            for (const system in placeholder[lang][collection]) {
                                placeholder[lang][collection][system] = removeFatherName(placeholder[lang][collection][system]);
                            }
                        } else {
                            placeholder[lang][collection] = placeholder[lang][collection].map(comment => {
                                if (!React.isValidElement(comment)) {
                                    for (let i = 0; i < transcriptions.length; i++) {
                                        if (comment.includes(transcriptions[i])) {
                                            comment =  comment.replace(transcriptions[i], removeFatherName(transcriptions[i]));
                                        }
                                    }
                                     
                                    return comment;
                                } else {
                                    return comment;
                                }
                            });
                        }
                    }
                }

                setAllResults(placeholder);
                setTranscription(removeFatherName(getPreferredTranscription(currentLang, props.process)));
                setOrigin(removeFatherName(transliterationBase[currentLang]));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showFatherName, originalResults, showedLang])

    const switchLanguage = (e) => {
        const languages = Object.keys(allResults);
        const newLang = showedLang === languages[0] ? languages[1] : languages[0];

        if (!showFatherNameToggle) {
            setTranscription(props.process.data.inhouseInfo.taalunieName ? props.process.data.inhouseInfo.taalunieName : getPreferredTranscription(newLang, props.process));
            setOrigin(`${transliterationBase[newLang]}${props.process.data.inhouseInfo.taalunieName ? `, ${getPreferredTranscription(newLang, props.process)}` : ''}`);
        }

        setShowedLang(newLang);
    }

    const toggleFatherName = (e) => {
        setShowFatherName(e.target.checked);
    }

    const [isCopied, setIsCopied] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsCopied(false);
    };

    function makeComment(comment, key = undefined) {
        return (
            <Alert severity={comment.includes('instellingen') ? 'warning' : 'info'} className={classes.comments} key={key}>
                {React.isValidElement(comment) ? comment : (<ReactMarkdown>{comment.replace(/<\/?[^>]+(>|$)/g, '')}</ReactMarkdown>)}
            </Alert>
        )
    }
    
    // Function to create list items for results
    function formListItems(items, comments, nameHistory) {
        // Copy object to make sure rerendering doesn't ruin comments
        let copiedItems = JSON.parse(JSON.stringify(items));

        // Push all items into a component
        let results = {
            comments: [],
            systems: [],
        }
        
        // Get all comments
        let allComments = [];
        if (Object.keys(copiedItems).includes('comments')) {
            allComments = [...copiedItems['comments'], ...comments];
            delete copiedItems['comments'];
        } else {
            allComments = [...comments];
        }

        results.comments.push(allComments.map((comment, index) => {
            return (
                <ListSubheader key={'c-' + index}>
                    {makeComment(comment)}
                </ListSubheader>
            )
        }));

        if (nameHistory) {
            results.comments.push(
                <ListSubheader key={'namehistory'}>
                    {nameHistory}
                </ListSubheader>
            );
        }

        Object.keys(copiedItems).forEach((category, index) => {
            let subItems = Object.keys(copiedItems[category]).map((system, index) => {
                return (
                    <ListItem key={'li-' + index}>
                        <ListItemText primary={copiedItems[category][system]} secondary={getSystemTooltip(system) ? (<>{getDisplayName(system)}{infoTooltip(getSystemTooltip(system))}</>) : getDisplayName(system)} className="result" />
                    </ListItem>
                );
            })

            results.systems.push(
                <>
                    <List key={'l-' + index} subheader={
                        <ListSubheader component="div">
                            <Typography variant="h6" className={classes.resultsSubheader}>
                                {category.charAt(0).toUpperCase() + category.slice(1)}
                            </Typography>
                        </ListSubheader>
                    }>
                        {subItems}
                    </List>
                    <Divider />
                </>
            )
        });

        return results;
    }
    
    const generateNameHistoryList = (items) => {
        if (items && items.length > 0) {
            const listItems = items.map((item, index) => {
                return (
                    <li key={`nh-${index}`}>
                        <strong>{item.transcription}</strong> ({item.from}-{item.to}, {item.name})
                    </li>
                );
            });

            return (
                <Alert severity="info" className={classes.comments}>
                    <AlertTitle>Naamsgeschiedenis</AlertTitle>
                    <ul className={classes.nameHistoryList}>
                        {listItems}
                    </ul>
                </Alert>
            );
        } else {
            return undefined;
        }
    }
   
    // Initially set variables
    let results;

    if (showedLang) {
        if (!showFatherNameToggle && Object.keys(transliterationBase).length > 0 && (![getPreferredTranscription(showedLang, props.process), props.process.data.inhouseInfo.taalunieName].includes(transcription) || origin === '')) {
            setTranscription(props.process.data.inhouseInfo.taalunieName ? props.process.data.inhouseInfo.taalunieName : getPreferredTranscription(showedLang, props.process));
            setOrigin(`${transliterationBase[selectedUserLang]}${props.process.data.inhouseInfo.taalunieName ? `, ${getPreferredTranscription(showedLang, props.process)}` : ''}`);
        }

        // Get list items
        if (Object.keys(allResults).length > 0 ) {
            let filteredResults = JSON.parse(JSON.stringify(allResults[showedLang]));

            if (Object.keys(filteredResults).includes('Nederlands')) {
                const wikipediaTransl = filteredResults['Nederlands'].wikipediaNL;
                const { Nederlands, ...resultsWithoutDutch } = filteredResults;

                if (wikipediaTransl && wikipediaTransl !== transcription) {
                    // Still show condensed Dutch results section
                    filteredResults = {
                        ...{
                            Nederlands: {
                                wikipediaNL: wikipediaTransl,
                            }
                        },
                        ...resultsWithoutDutch
                    }
                } else {
                    filteredResults = resultsWithoutDutch;
                }
            }

            results = formListItems(filteredResults, props.process.data.inhouseInfo.comments, selectedUserLang !== 'ar' ? generateNameHistoryList(props.process.data.externalInfo.data.nameHistory[showedLang]): undefined);
        } else {
            results = (<></>);
        }
    }

    if (Object.keys(allResults).length > 0) {
        return (
            <>
                <Card>
                    <CardHeader 
                        className={classes.resultsHeaderContainer}
                        {...(props.process.data.inhouseInfo.taalunieName ? {
                            avatar: (
                                <div className={classes.taalunieSuggestion}>
                                    <Avatar className={classes.badge}>
                                        <img src={taalunieLogo} width="40" alt="Nederlandse Taalunie" />
                                    </Avatar>
                                </div>
                        ) } : {})}
                        title={
                            <div>
                                <div className={classes.transcriptionInfo}>
                                    <div className={classes.transcriptionInfoContent}>
                                        <Typography component="h4" variant="h4">
                                            {props.process.data.inhouseInfo.taalunieName ? props.process.data.inhouseInfo.taalunieName : transcription}

                                            <Tooltip title="Kopieer deze schrijfwijze naar het klembord" placement="right-start">
                                                <CopyToClipboard text={props.process.data.inhouseInfo.taalunieName ? props.process.data.inhouseInfo.taalunieName : transcription} onCopy={() => setIsCopied(true)}>
                                                    <IconButton aria-label="delete" style={{ marginLeft: '2.5px' }}>
                                                        <FileCopyOutlinedIcon fontSize="small" />
                                                    </IconButton>
                                                </CopyToClipboard>
                                            </Tooltip>
                                        </Typography>
                                        <ListSubheader component="div" className={classes.originContainer}>
                                            <Typography variant="h6" className={classes.origin}>
                                                ({origin})
                                            </Typography>

                                            <div className={classes.iconContainer}>
                                                {props.process.data.externalInfo.wikiTitle &&  
                                                    <Tooltip title={`Ga naar het artikel over ${props.process.input.userInput.type === 'person' ? 'deze persoon' : props.process.input.userInput.type === 'geo' ? 'deze plaats' : 'dit onderwerp'} in de Nederlandstalige Wikipedia`} placement="right-start">  
                                                        <div className={classes.externalLink}>
                                                            <a href={'https://nl.wikipedia.org/wiki/' + props.process.data.externalInfo.wikiTitle.replace(' ', '_')} target="_blank" rel="noopener noreferrer">
                                                                <Avatar className={classes.externalLinkLogo}>
                                                                    <img src={wikipediaLogo} width="40" alt="Wikipedia" />
                                                                </Avatar>
                                                                <Avatar className={classes.externalLinkIcon}>
                                                                    <img src={externalLinkIcon} width="10" height="10" alt="Wikipedia" />
                                                                </Avatar>
                                                            </a>
                                                        </div>
                                                    </Tooltip>
                                                }
                                                {props.process.data.externalInfo.data.coords && props.process.data.externalInfo.data.coords.latitude && props.process.data.externalInfo.data.coords.longitude &&
                                                    <>
                                                        <Tooltip title="Laat de locatie van deze plaats zien op de kaart" placement="right-start">   
                                                            <Avatar className={classes.badge} onClick={() => setShowMap(true)}>
                                                                <img src={mapMarkerIcon} width="25" alt="Laat locatie zien" />
                                                            </Avatar>
                                                        </Tooltip>

                                                        <Dialog onClose={() => setShowMap(false)} open={showMap} aria-labelledby="map">
                                                            <DialogContent className={classes.mapModal}>
                                                                <MapContainer center={[props.process.data.externalInfo.data.coords.latitude, props.process.data.externalInfo.data.coords.longitude]} zoom={8} className={classes.map}>
                                                                    <TileLayer
                                                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                    />
                                                                    <Marker position={[props.process.data.externalInfo.data.coords.latitude, props.process.data.externalInfo.data.coords.longitude]}>
                                                                        <Popup>
                                                                            <Typography variant="body1">
                                                                                {props.process.data.inhouseInfo.taalunieName ? props.process.data.inhouseInfo.taalunieName : transcription}
                                                                            </Typography>
                                                                        </Popup>
                                                                    </Marker>
                                                                </MapContainer>
                                                            </DialogContent>
                                                        </Dialog>
                                                    </>
                                                }
                                            </div>
                                        </ListSubheader>
                                    </div>

                                    {props.process.data.externalInfo.data.image ? (
                                        <img src={props.process.data.externalInfo.data.image} className={classes.cover} alt="" />
                                    ) : (<div></div>)}
                                </div>

                                
                                
                                <div className={`${classes.toggles} ${showFatherNameToggle && Object.keys(allResults).length < 2 ? classes.flexEnd : ''}`}>
                                    {/* Offer a switch between language if two sets of results are available  */}
                                    {Object.keys(allResults).length === 2 &&
                                        <div className={classes.resultsTitle}>
                                            <Typography variant="caption" display="block">
                                                Oekraïens
                                            </Typography>
                                            <Switch color="default" checked={showedLang === 'ru'} onChange={switchLanguage} />
                                            <Typography variant="caption" display="block">
                                                Russisch
                                            </Typography>
                                        </div>
                                    }
                                    {showFatherNameToggle &&
                                        <div className={classes.fatherNameToggle}>
                                            <Switch color="primary" onChange={toggleFatherName} defaultChecked={!props.process.data.externalInfo.id} />
                                            <Typography variant="caption" display="block">
                                                Laat vadersnaam zien
                                            </Typography>
                                        </div>
                                    }
                                </div>
                            </div>
                        } 
                    />

                    <div style={{ marginBottom: '16px' }}>
                        {results.comments}
                    </div>

                    <Divider />

                    <div className={classes.resultsHeading}>
                        <div className={classes.resultsTitle}>
                            <Typography variant="h5">
                                Overige schrijfwijzen
                            </Typography>
                        </div>
                    </div> 
                    
                    {results.systems}

                    {props.process.data.externalInfo.id && 
                        <Typography variant="caption" display="block" gutterBottom className={classes.credits}>
                            Hierboven is onder meer gebruikgemaakt van <a target="_blank" rel="noopener noreferrer" href={'https://wikidata.org/wiki/' + props.process.data.externalInfo.id}>gegevens afkomstig uit Wikidata</a>, een project van de <a target="_blank" rel="noopener noreferrer" href="https://www.wikimediafoundation.org">Wikimedia Foundation</a>. 
                            {props.process.data.externalInfo.data.image ? (<> Het beeld is afkomstig van <a target="_blank" rel="noopener noreferrer" href={props.process.data.externalInfo.data.image.replace('Special:FilePath/', 'File:').split('?width=')[0]}>Wikimedia Commons</a>.</>) : ''}
                        </Typography>
                    }
                </Card>

                <Snackbar open={isCopied} autoHideDuration={2000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="info">
                        De naam is gekopieerd naar het klembord!
                    </Alert>
                </Snackbar>
            </>
        )
    } else {
        return (
            <div></div>
        )
    }
}
