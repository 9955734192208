import capitalizeAllWords from './../utils/capitalizeAllWords';
// import addOptionalLines from './../utils/addOptionalLines';
import checkLanguageValidity from './../utils/checkLanguageValidity';
import getNijmegen from './../utils/getNijmegen';
import removeOptionalLetters from './../utils/removeOptionalLetters';
import { setCORS } from "google-translate-api-browser";
import transliterateArabic from './../utils/transliterateArabic';
import txt from './../data/input.ar.txt';

// function objectFlip(obj) {
//     const newObj = Object.keys(obj).reduce((ret, key) => {
//         ret[obj[key]] = key;
//         return ret;
//     }, {});

//     Object.keys(newObj).forEach((letter) => {
//         if (letter.length === 0) {
//             delete newObj[letter];
//         }
//     })

//     return newObj;
// }

async function getInput(process, input, languageTest, lang) {
    const wikiInput = process.data.externalInfo.data[lang] ? process.data.externalInfo.data[lang].wikipedia || process.data.externalInfo.data[lang].wikidata ? process.data.externalInfo.data[lang].wikipedia ? process.data.externalInfo.data[lang].wikipedia : process.data.externalInfo.data[lang].wikidata : undefined : undefined;

    const generateNijmegenDMG = async (arInput, latinInput) => {
        try {
            // Create input file
            const inputBlob = new Blob([txt.replace('[INPUT]', arInput)], { encoding: "UTF-8", type: "text/plain;charset=UTF-8" });

            // Generate DMG+ for the Arabic string
            const determinedInput = await getNijmegen(arInput, 'ar2dmgplus', inputBlob, latinInput);
            return determinedInput;
        } catch (e) {
            console.log('Error in getting DMG+ from Nijmegen...');

            // Backup option via tables
            return transliterateArabic(arInput, 'ar2dmg', removeOptionalLetters(require('../data/decotype2transl/letters.json')), removeOptionalLetters(require('../data/decotype2transl/exceptions.json')), removeOptionalLetters(require('../data/decotype2transl/positional.json')), undefined, false);
        }
    }

    if (lang !== 'ar') {
        if (wikiInput) {
            return wikiInput;
        } else if (languageTest.languageValidity[lang]) {
            return input.searchString;
        }
    } else if (wikiInput || languageTest.languageValidity[lang]) {
        // There's an Arabic input available
        const arInput = wikiInput ? wikiInput : input.searchString.toLowerCase();

        let comparisonInput;
        try {
            comparisonInput = process.data.externalInfo.data.nl ? process.data.externalInfo.data.nl.wikipedia || process.data.externalInfo.data.nl.wikidata : process.data.externalInfo.data.de ? process.data.externalInfo.data.de.wikipedia || process.data.externalInfo.data.de.wikidata : process.data.externalInfo.data.en ? process.data.externalInfo.data.en.wikipedia || process.data.externalInfo.data.en.wikidata : process.data.externalInfo.data.fr.wikidata;
        } catch(error) {
            const translate = setCORS(sessionStorage.getItem('CORS-proxy'));
            const res = await translate(capitalizeAllWords(arInput), { from: 'ar', to: 'en' }); 
            comparisonInput = res.text;
        }

        const output = generateNijmegenDMG(arInput, comparisonInput);
        return output;
    }

    // No wikidata, so get Nijmegen input
    if (lang === 'ru') {
        // Get Nijmegen input, but only for Russian, Arabic not supported yet
        const determinedInput = await getNijmegen(input.searchString, 'lat2ru');
        languageTest.languageIsConstructed[lang] = true;
        return determinedInput;
    } else {
        // Arabic language with Latin input: DMG needs to be generated
        const selectedLang = process.data.languageTest.determinedLanguage;

        // Get input
        let inputString;
        if (process.data.externalInfo.id) {
            const setInputString = (wikiLang) => {
                const labels = process.data.externalInfo.data[wikiLang];
                if (labels && (labels.wikipedia || labels.wikidata)) {
                    inputString = labels.wikipedia ? labels.wikipedia : labels.wikidata;
                }
            }

            // eslint-disable-next-line default-case
            switch (selectedLang) {
                case 'Populair Nederlands':
                    setInputString('nl');
                    break;
                case 'Populair Frans':
                    setInputString('fr');
                    break;
                case 'Populair Engels':
                    setInputString('en');
                    break;
                case 'Populair Duits':
                    setInputString('de');
                    break;
            }
        }

        // Last resort: input string
        if (!inputString) {
            inputString = input.searchString.toLowerCase();

            // Change first letters
            const assimilationPattern = /(?:a|e)(l|t|ṯ|ṭ|d|ḏ|ḍ|r|z|ẓ|š|ṣ|s|n)(?:-| )(l|t|ṯ|ṭ|d|ḏ|ḍ|r|z|ẓ|š|ṣ|s|n)/gm;
            const matches = [...input.searchString.toLowerCase().matchAll(assimilationPattern)];

            matches.forEach(match => {
                // Only revert assimilation if the assimilation is applied correctly
                if (match[1] === match[2]) {
                    inputString = inputString.replace(match[0], `al-${match[2]}`);
                }
            });
        }

        const translate = setCORS(sessionStorage.getItem('CORS-proxy'));
        const res = await translate(capitalizeAllWords(inputString), { from: 'en', to: 'ar' });        

        // Check to see whether the output is actually Arabic
        if (checkLanguageValidity(res.text, 'ar')) {
            const output = generateNijmegenDMG(res.text, inputString);
            return output;
        } else {
            throw new Error('Not valid Arabic!');
        }
            
        // } catch (error) {
        //     // Translation didn't work out, manual route
        //     console.log(error);

        //     // Reverse input/output
        //     const letters = {
        //         [selectedLang]: addOptionalLines(objectFlip(require('../data/decotype2transl/letters.json')[selectedLang]))
        //     };
        //     const exceptions = {
        //         [selectedLang]: addOptionalLines(objectFlip(require('../data/decotype2transl/exceptions.json')[selectedLang]))
        //     };
        //     let positional = {
        //         [selectedLang]: require('../data/decotype2transl/positional.json')[selectedLang]
        //     };

        //     if (selectedLang.includes('Populair')) {
        //         // Add extension for popular to positional
        //         const extension = require('../data/settings/popularextension.json')[selectedLang];

        //         for (const position in positional[selectedLang]) {
        //             if (Object.keys(extension).includes(position)) {
        //                 positional[selectedLang][position] = {
        //                     ...extension[position],
        //                     ...positional[selectedLang][position],
        //                 }
        //             }
        //         }
        //     }

        //     // Flip positional object
        //     Object.keys(positional[selectedLang]).forEach(key => {
        //         positional[selectedLang][key] = objectFlip(positional[selectedLang][key]);
        //     });

        //     // Add optional lines after transformation
        //     positional[selectedLang] = addOptionalLines(positional[selectedLang]);

        //     // Determine DMG 
        //     const determinedInput = transliterateArabic(inputString.toLowerCase(), selectedLang, letters, exceptions, positional, selectedLang === 'Populair Frans' ? { [selectedLang]: removeOptionalLetters(objectFlip(require('../data/settings/popularfrenchextension.json')[selectedLang])) } : undefined, true);

        //     return determinedInput;
        // }
    }
}

export default async function determineTransliterationInput(process) {
    const input = process.input.userInput;
    const languageTest = process.data.languageTest;

    // Object to hold values
    let transliterationInput = {};
    switch (input.country === 'unknown' ? input.lang : input.country) {
        // Bilingual Ukranian issues
        case 'uk':
            if (process.data.inhouseInfo.renamedIn2016 && process.data.inhouseInfo.ukRenamings2016.new['uk']) {
                transliterationInput['uk'] = Object.values(process.data.inhouseInfo.ukRenamings2016.new['uk'])[0];
            } else if (process.data.externalInfo.data.uk && (process.data.externalInfo.data.uk.wikipedia || process.data.externalInfo.data.uk.wikidata)) {
                transliterationInput['uk'] = process.data.externalInfo.data.uk.wikipedia ? process.data.externalInfo.data.uk.wikipedia : process.data.externalInfo.data.uk.wikidata;
            } else if (languageTest.languageValidity.uk && languageTest.determinedLanguage === 'uk') {
                transliterationInput['uk'] = input.searchString;
            }

            if (process.data.inhouseInfo.renamedIn2016 && process.data.inhouseInfo.ukRenamings2016.new['ru']) {
                transliterationInput['ru'] = Object.values(process.data.inhouseInfo.ukRenamings2016.new['ru'])[0];
            } else if (process.data.externalInfo.data.ru && (process.data.externalInfo.data.ru.wikipedia || process.data.externalInfo.data.ru.wikidata)) {
                transliterationInput['ru'] = process.data.externalInfo.data.ru.wikipedia ? process.data.externalInfo.data.ru.wikipedia : process.data.externalInfo.data.ru.wikidata;
            } else if (languageTest.languageValidity.ru && languageTest.determinedLanguage === 'ru') {
                transliterationInput['ru'] = input.searchString;
            }

            // Check if we got all inputs
            const foundLangs = Object.keys(transliterationInput);
            if (foundLangs.length === 1) {
                // Use Nijmegen to get the other language
                const targetLang = Object.values(foundLangs)[0] === 'uk' ? 'ru' : 'uk';
                transliterationInput[targetLang] = await getNijmegen(transliterationInput[foundLangs[0]], targetLang === 'uk' ? 'ru2uk' : 'uk2ru');
                languageTest.languageIsConstructed[targetLang] = true;
            } else if (foundLangs.length === 0) {
                // None of both: assume it's Latin, use Nijmegen to get both Russian and Ukranian
                const lang = languageTest.determinedLanguage;
                const neededLang = lang === 'uk' ? 'ru' : 'uk';

                transliterationInput[lang] = await getNijmegen(input.searchString, `lat2${lang}`);
                transliterationInput[neededLang] = await getNijmegen(transliterationInput[lang], `${lang}2${neededLang}`);
                languageTest.languageIsConstructed = {
                    ...languageTest.languageIsConstructed,
                    ...{
                        uk: true,
                        ru: true,
                    }
                }
            }

            break;

        case 'ru':
            transliterationInput['ru'] = await getInput(process, input, languageTest, 'ru');
            break;

        // Arabic countries
        default:
            transliterationInput['ar'] = await getInput(process, input, languageTest, 'ar');
    }

    process.data.transliterationBase = transliterationInput;
    return process;
}
