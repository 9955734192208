const systemNames = {
    'populairNL': 'Algemeen',
    'populairNLTolerantKlinkerbotsing': 'Populair',
    'populairNLNietVersimpeld': 'Algemeen (niet versimpeld)',
    'enAanvullendeVarianten_populairENNietVersimpeld': 'Algemeen (niet versimpeld)',
    'enAanvullendeVarianten_officieel': 'Officieel gebruik',
    'wikipediaNL': 'Wikipedia (Nederlandstalig)',
    'populairDE': 'Algemeen',
    'populairEN': 'Algemeen',
    'deAanvullendeVarianten_populairDESimpel': 'Algemeen (versimpeld)',
    'deAanvullendeVarianten_populairDEDDR': 'DDR',
    'enVariant': 'Variant',
    'enAanvullendeVarianten_enVariant': 'Variant',
    'variant2': 'Variant',
    'enAanvullendeVarianten_variant1': 'Variant',
    'enAanvullendeVarianten_variant2': 'Variant',
    'kbNL': 'Koninklijke Bibliotheek (NL)',
    'LOC': 'Library of Congress (VS)',
    'BLpre1975': 'British Library (< 1975)',
    'BLpost1975': 'British Library (≥ 1975)',
    'wetenschappelijk': 'Wetenschappelijk (Europa)',
    'wetenschappelijkEngels': 'Wetenschappelijk (Angelsaksisch)',
    'wetenschappelijkEngelsSimpel': 'Wetenschappelijk (Angelsaksisch, versimpeld)',
    'bgnPcgn': 'Brits en Amerikaans (BGN-PCGN)',
    'verkeersborden': 'Verkeersborden',
    'stabi': 'Staatsbibliothek (Berlijn)',
    'ISO1995': 'ISO 9:1995 (= GOST 7.79-2000 systeem A)',
    'GOST2000B': 'GOST 7.79-2000 systeem B',
    'paspoort2013': 'Paspoort (2013-), rijbewijs (2016-)',
    'paspoort2010': 'Paspoort (2010-2013)',
    'paspoort2007': 'Paspoort (2007-2010)',
    'paspoort2004': 'Paspoort (2004-2007)',
    'paspoort1997': 'Paspoort (1997-2010)',
    'paspoort1995': 'Paspoort (1995-2004)',
    'paspoortUssr': 'Paspoort (Sovjet-Unie)',
    'rijbewijs2000': 'Rijbewijs (2000-2016)',
    'vn': 'Verenigde Naties',
}

// For Russian and Ukranian, map internal system name to display name
export default function getDisplayName(system) {
    return systemNames[system] || system;
}
