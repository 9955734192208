import React from 'react';
import { Icon, IconButton, Tooltip } from '@material-ui/core';

export default function infoTooltip(title) {
    return (
        <Tooltip title={title} placement="right-start">
            <IconButton size="small" style={{
                fontSize: '0.9rem',
                color: '#2196f3',
            }}>
                <Icon fontSize="small" style={{
                    marginBottom: '6px',
                    fontSize: '0.9rem',
                }}>info</Icon>
            </IconButton>
        </Tooltip>
    )
}