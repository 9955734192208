import getPreferredTranscription from './../utils/getPreferredTranscription';

export default function getInhouseComments(process) {
    let comments = [];

    const languageNames = {
        ar: 'Arabisch',
        uk: 'Oekraïens',
        ru: 'Russisch',
    }
    const arabicCountryNames = { dz: 'Algerije', ma: 'Marokko', tn: 'Tunesië', ly: 'Libië', eg: 'Egypte', sd: 'Soedan', ps: 'Palestina', lb: 'Libanon', sy: 'Syrië', iq: 'Irak', kw: 'Koeweit', jo: 'Jordanië', sa: 'Saoedi-Arabië', ye: 'Jemen', ae: 'Verenigde Arabische Emiraten', om: 'Oman', bh: 'Bahrein', qa: 'Qatar', mr: 'Mauritanië', code: 'mr'}

    // Variable declarations
    const inhouseInfo = process.data.inhouseInfo;
    const externalInfo = process.data.externalInfo;
    const selectedLang = process.input.userInput.lang;
    const settings = process.input.settings;
    const selectedCountry = process.input.userInput.country;
    const selectedType = process.input.userInput.type;
    const constructedLanguage = process.data.languageTest.languageIsConstructed;
    const taalunieName = process.data.inhouseInfo.taalunieName;
    const results = process.data.results;
    const transliterations = {
        ru: (selectedLang === 'ru' || selectedLang === 'uk') ? getPreferredTranscription('ru', process) : undefined,
        uk: (selectedLang === 'uk' || selectedCountry === 'uk') ? getPreferredTranscription('uk', process) : undefined,
    }
    transliterations['nl'] = inhouseInfo.taalunieName ? inhouseInfo.taalunieName : selectedLang === 'ar' ? getPreferredTranscription('ar', process) : transliterations.uk || transliterations.ru;

    // Function to add comment to specific language
    const addCommentToLang = (comment, lang) => {
        if (Object.keys(results[lang]).includes('comments')) {
            results[lang].comments = [...results[lang].comments, ...[comment]];
        } else {
            results[lang].comments = [comment];
        }
    }

    // Difference between Taalunie and transliteration
    if (taalunieName) {
        Object.keys(results).forEach(lang => {
            addCommentToLang(`Bovenstaande naam staat in [de lijst Buitenlandse Aardrijkskundige Namen van de Nederlandse Taalunie](http://namen.taalunie.org/).${getPreferredTranscription(lang, process) !== taalunieName ? ` De eigenlijke omzetting van de ${languageNames[lang]}e naam luidt **${transliterations[lang]}**.` : ''}`, lang);
        })
    }

    // Language is determined in Nijmegen
    Object.keys(results).forEach(lang => {
        if (constructedLanguage[lang]) {
            addCommentToLang(`De schrijfwijze van de naam in het ${languageNames[lang]} is gereconstrueerd en niet 100% zeker. Dit kan gevolgen hebben voor de getoonde resultaten.`, lang)
        }
    })

    // Inhouse info boolean comments
    if (inhouseInfo.renamedIn2016) {
        const constructComment = lang => `In 2016 zijn in Oekraïne plaatsnamen met een communistisch karakter verboden. Daarvoor heette deze plaats ${lang === 'ru' ? 'in het Russisch' : ''} **${inhouseInfo.oldNames[lang]}**.${inhouseInfo.ukIsDNR || inhouseInfo.ukIsLNR ? ' In het gebied zelf wordt vastgehouden aan de oude naam.' : ''}`;

        if (selectedLang === 'uk' && Object.keys(inhouseInfo.oldNames).length === 2) {
            // Renamed for both languages
            ['uk', 'ru'].forEach((lang) => {
                const comment = constructComment(lang);

                if (Object.keys(results[lang]).includes('comments')) {
                    results[lang].comments = [...results[lang].comments, ...[comment]];
                } else {
                    results[lang].comments = [comment];
                }
            })
        } else {
            // Only renamed for one language
            const comment = constructComment(selectedLang);

            if (Object.keys(results[selectedLang]).includes('comments')) {
                results.comments = [...results.comments, ...[comment]];
            } else {
                results.comments = [comment];
            }
        }
    }
    if (inhouseInfo.isCrimea) {
        if (selectedLang === 'uk') {
            comments.push('Deze plaats ligt op de Krim. Hoewel onder de huidige Oekraïense wet alleen de Oekraïense namen officieel zijn, is sinds de annexatie van het gebied door Rusland in 2014 het Russisch eens te meer dominant.');
        } else {
            comments.push('Deze plaats ligt op de Krim. Zeker sinds de annexatie in 2014 door Rusland is het Russisch er eens te meer dominant. Onder de huidige Oekraïense wet is evenwel alleen de Oekraïense naam officieel.')
        }
    }
    if (inhouseInfo.newNameCrimea) {
        ['uk', 'ru'].forEach(lang => {
            if (inhouseInfo.newNameCrimea.new[lang]) {
                addCommentToLang(lang === 'uk' ? `Oekraïne heeft voor deze plaats de nieuwe naam **${inhouseInfo.newNameCrimea.new[lang].transcription}** (${inhouseInfo.newNameCrimea.new[lang].naam}) vastgesteld. Deze nieuwe naam wordt echter pas van kracht als de Krim weer onder Oekraïens bestuur komt.` : `Oekraïne heeft voor deze plaats een nieuwe naam vastgesteld. Deze naam, die in het Russisch **${inhouseInfo.newNameCrimea.new[lang].transcription}** (${inhouseInfo.newNameCrimea.new[lang].naam}) luidt, wordt echter pas van kracht als de Krim weer onder Oekraïens bestuur komt.`, lang)
            }
        })
    }
    if (inhouseInfo.ukIsDNR) {
        if (selectedLang === 'uk') {
            comments.push('Deze plaats ligt in het oosten van Oekraïne, in de zelfverklaarde Volksrepubliek Donetsk. Onder de Oekraïense wet is alleen de Oekraïense naam officieel. In de praktijk is in dit gebied het Russisch dominant.');
        } else {
            comments.push('Deze plaats ligt in het oosten van Oekraïne, in de zelfverklaarde Volksrepubliek Donetsk. Onder de Oekraïense wet is alleen de Oekraïense naam officieel. In de praktijk is in dit gebied het Russisch dominant.')
        }
    }
    if (inhouseInfo.ukIsLNR) {
        if (selectedLang === 'uk') {
            comments.push('Deze plaats ligt in het oosten van Oekraïne, in de zelfverklaarde Volksrepubliek Loegansk. Onder de Oekraïense wet is alleen de Oekraïense naam officieel. In de praktijk is in dit gebied het Russisch dominant.');
        } else {
            comments.push('Deze plaats ligt in het oosten van Oekraïne, in de zelfverklaarde Volksrepubliek Loegansk. Onder de Oekraïense wet is alleen de Oekraïense naam officieel. In de praktijk is in dit gebied het Russisch dominant.')
        }
    }

    // Vowel collision alert
    if (Object.keys(inhouseInfo.vowelCollisionOutputs).length > 0) {
        Object.keys(inhouseInfo.vowelCollisionOutputs).forEach(lang => {
            addCommentToLang(`Overeenkomstig je instellingen voor klinkerbotsing is voor de schrijfwijze ${transliterations[lang]} gekozen. De ${!settings[lang + 'TolerantVowelCollision'] ? 'minder strikte' : 'striktere'} schrijfwijze is **${!settings[lang + 'TolerantVowelCollision'] ? inhouseInfo.vowelCollisionOutputs[lang].nonStrict : inhouseInfo.vowelCollisionOutputs[lang].strict}**.`, lang);
        })
    }

    // Location-bound settings
    if (inhouseInfo.isCrimea) {
        const preferredLang = settings.ruOnCrimea ? 'ru' : 'uk';
        addCommentToLang(`Overeenkomstig je instellingen voor de Krim wordt hier de ${languageNames[preferredLang]}e schrijfwijze eerst weergegeven.`, preferredLang);
    }
    if (inhouseInfo.ukIsDNR || inhouseInfo.ukIsLNR) {
        const preferredLang = settings.ruInDNRLNR ? 'ru' : 'uk';
        addCommentToLang(`Overeenkomstig je instellingen voor de zelfverklaarde Volksrepublieken Donetsk en Loegansk wordt hier de ${languageNames[preferredLang]}e schrijfwijze eerst weergegeven.`, preferredLang);
    }

    // Wikipedia comments
    if (selectedLang !== 'ar') {
        if (externalInfo.id && externalInfo.wikiTitle && externalInfo.data.nl.wikipedia.split(' ').filter(word => !transliterations.nl.includes(word)).length > 0) {
            comments.push(`De Nederlandstalige Wikipedia over ${selectedType === 'geo' ? 'deze plaats' : selectedType === 'person' ? 'deze persoon' : 'dit onderwerp'} gebruikt in de titel de naam [${externalInfo.data.nl.wikipedia}](${'https://nl.wikipedia.org/wiki/' + externalInfo.wikiTitle.replace(' ', '_')}).`);
        }
    }

    // Country specific comments for Ukraine
    if (selectedCountry === 'uk') {
        // Alert bilinguality for Russian
        if (!inhouseInfo.isCrimea) {
            addCommentToLang(`Hoewel het Russisch in Oekraïne wijdverbreid is en er redenen kunnen zijn om in het Nederlands de Russische naam te gebruiken, is de officiële taal van het land uitsluitend het Oekraïens. In het Oekraïens luidt de naam **${transliterations.uk}**.`, 'ru');
        }

        const identicalInDutch = transliterations.uk === transliterations.ru;
        const ruBase = process.data.transliterationBase.ru;
        const ukBase = process.data.transliterationBase.uk;
        if (ruBase === ukBase) {
            // Russian and Ukranian is the same
            if (identicalInDutch) {
                // Dutch version also the same
                comments.push(`De Oekraïense en de Russische naam zijn geheel identiek (${ruBase}). Ook in het Nederlands worden ze identiek weergegeven (${transliterations.uk}).`);
            } else {
                comments.push(`Hoewel de naam er in het Oekraïens en het Russisch hetzelfde uitziet (${ruBase}), wordt hij in het Nederlands verschillend geschreven, namelijk als **${transliterations.uk}** (Oekraïens) en **${transliterations.ru}** (Russisch). Overigens is alleen de Oekraïense naam officieel.`);
            }
        } else if (identicalInDutch) {
            comments.push(`Hoewel de naam er in het Oekraïens (**${ukBase}**) en het Russisch (**${ruBase}**) verschillend uitziet, wordt hij in het Nederlands identiek geschreven.`);
        }

        // Simplification alert
        if (Object.keys(inhouseInfo.simplificationOutputs).length > 0) {
            addCommentToLang(`Overeenkomstig je instellingen voor complexe medeklinkercombinaties is voor de schrijfwijze ${transliterations.uk} gekozen. De ${settings.ukNoSimplification ? '' : 'niet-'}versimpelde schrijfwijze luidt **${settings.ukNoSimplification ? inhouseInfo.simplificationOutputs.simplified : inhouseInfo.simplificationOutputs.nonSimplified}**.`, 'uk');
        }
    }

    if (selectedLang === 'ar') {
        if (process.data.languageTest.determinedLanguage && process.data.languageTest.determinedLanguage.includes('Populair')) {
            comments.push(`Let op: dit is een schrijfwijze gebaseerd op algemeen gebruik in het ${process.data.languageTest.determinedLanguage.split(' ')[1]}. Dit betekent dat er naast de weergegeven schrijfwijze mogelijk ook andere (minder voorkomende) schrijfwijzen in omloop zijn.`)
        }

        if (['ma', 'tn', 'dz', 'lb', 'mr'].includes(selectedCountry)) {
            // French influence
            comments.push(`${arabicCountryNames[selectedCountry]} is een voormalig Frans${selectedCountry !== 'lb' ? 'e kolonie' : ' mandaatgebied'}. ${selectedCountry === 'mr' ? 'Invloed van het Frans is hierdoor nog steeds te vinden in voornamelijk de media.' : 'Invloed van het Frans is hierdoor nog steeds te vinden bij de overheid, evenals in onder meer het onderwijs en de media.'}`);
        } else if (['iq', 'ps'].includes(selectedCountry)) {
            // British influence (mandate)
            comments.push(`${arabicCountryNames[selectedCountry]} is een voormalig Brits mandaatgebied.`);
        } else if (['kw', 'jo', 'ye', 'ae', 'bh', 'qa', 'om'].includes(selectedCountry)) {
            // British influence (protectorate)
            comments.push(`${selectedCountry !== 'ae' ? `${arabicCountryNames[selectedCountry]} is` : `De ${arabicCountryNames[selectedCountry]} vormen`} een voormalig Brits protectoraat.`);
        } else if (['eg', 'sd'].includes(selectedCountry)) {
            // British influence (colony)
            comments.push(`${arabicCountryNames[selectedCountry]} is een voormalig Britse kolonie.`);
        } else if (selectedCountry === 'ly') {
            comments.push(`Alhoewel ${arabicCountryNames[selectedCountry]} een voormalig Italiaanse kolonie is, hebben het Frans en Engels er vandaag de dag meer invloed als vreemde taal.`);
        } else if (selectedCountry === 'sy') {
            comments.push(`Alhoewel ${arabicCountryNames[selectedCountry]} een voormalig Frans protectoraat is, heeft het Engels er vandaag de dag meer invloed als vreemde taal.`);
        } else if (selectedCountry === 'sa') {
            comments.push(`Door de nauwe economische en politieke banden met de Verenigde Staten is het Engels een belangrijke vreemde taal in ${arabicCountryNames[selectedCountry]}.`);
        }
    }

    inhouseInfo.comments = [...inhouseInfo.comments, ...comments];

    return process;
} 
