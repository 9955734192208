export default async function getInhouseInfo(process) {
    if (process.data.externalInfo.id !== undefined) {
        const queryEndpoint = 'https://www.taalmannetje.nl/transcriptor/eigenInfoJSON.php';

        // Construct POST request to external server for inhouse info
        const externalInfo = process.data.externalInfo;
        const userInput = process.input.userInput;
        let query = {
            type: userInput.type,
            land: userInput.country !== 'uk' ? userInput.country : 'ua',
            externeInfo: {
                wikidata: externalInfo.id
            },
            ...(externalInfo.data.koatuu && { koatuu: externalInfo.data.koatuu }),
            ...(externalInfo.data.oktmo && { oktmo: externalInfo.data.oktmo }),
            ...(externalInfo.data.okato && { okato: externalInfo.data.okato }),
        }

        // Get results
        let response;
        const body = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/javascript, */*; q=0.01',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': 'Basic bWFydGlqbjpkYXRzeWItbWl0cWlwLTd4eW1wRQ==',
            },
            mode: 'cors',
            body: 'q=' + JSON.stringify(query)
        }
        try {
            response = await fetch(sessionStorage.getItem('CORS-proxy') + queryEndpoint, body);
        } catch(e) {
            const backup = sessionStorage.getItem('backup-proxy');
            sessionStorage.setItem('CORS-proxy', backup);
            response = await fetch(backup + queryEndpoint, body)
        }
        
        const results = await response.json();

        // Assign to process
        process.data.inhouseInfo = {
            ...(results.isKrim ? { isCrimea: results.isKrim } : { isCrimea: undefined }),
            ...(results.nieuweNaamKrim ? { newNameCrimea: {...{new: results.nieuweNaamKrim.nieuweNaam}, ...{old: results.nieuweNaamKrim.oudeNaam}} } : { newNameCrimea: undefined }),
            ...(results.isLNR ? { ukIsLNR: results.isLNR } : { ukIsLNR: undefined }),
            ...(results.isDNR ? { ukIsDNR: results.isDNR } : { ukIsDNR: undefined }),
            ...(results.hernoemingUa2016 ? { ukRenamings2016: {...{new: results.hernoemingUa2016.nieuweNaam}, ...{old: results.hernoemingUa2016.oudeNaam}} } : { ukRenamings2016: undefined }),
            ...(results.taalunieNaam ? { taalunieName: results.taalunieNaam } : { taalunieName: undefined }),
            ...(results.opmerkingen ? { comments: results.opmerkingen } : { comments: undefined }),
            ...{renamedIn2016: Boolean(results.hernoemingUa2016)},
            ...{oldNames: {}},
            ...{simplificationOutputs: {}},
            ...{vowelCollisionOutputs: {}},
        }

        // Special problem: when user selects Russian but location is on Crimea, change country to Ukraine
        if (results.isKrim) {
            process.input.userInput.country = 'uk';
        }

        const renamingInfo = results.hernoemingUa2016;
        if (renamingInfo) {
            const renameYear = renamingInfo.ingangsdatum ? renamingInfo.ingangsdatum.split('-')[0] : '2016';

            Object.keys(renamingInfo.nieuweNaam).forEach(lang => {
                const oldName = renamingInfo.oudeNaam[lang].naam;
                const newName = renamingInfo.nieuweNaam[lang].naam;

                // Add old names to info
                process.data.inhouseInfo.oldNames[lang] = oldName; 

                // Optionally add to name history
                if (!externalInfo.data.nameHistory[lang]) {
                    externalInfo.data.nameHistory[lang] = [
                        {
                            name: oldName,
                            from: '',
                            to: renameYear,
                        },
                        {
                            name: newName,
                            from: renameYear,
                            to: '',
                        }
                    ]
                } else {
                    if (!externalInfo.data.nameHistory[lang].map(rename => rename.from).includes(renameYear)) {
                        externalInfo.data.nameHistory[lang].push({
                            name: newName,
                            from: renameYear,
                            to: '',
                        })
                    }
                    if (!externalInfo.data.nameHistory[lang].map(rename => rename.to).includes(renameYear)) {
                        externalInfo.data.nameHistory[lang].push({
                            name: oldName,
                            from: '',
                            to: renameYear,
                        })
                    }
                }
            })
        }

        // Sort name history
        Object.keys(externalInfo.data.nameHistory).forEach(lang => {
            externalInfo.data.nameHistory[lang].sort((obj1, obj2) => {
                if (!obj1.from || !obj2.from) {
                    // One of both does not have a from date: this is always the start of the chronology
                    return !obj1.from ? -1 : 1;
                } else {
                    return obj1.from - obj2.from;
                }
            })

            // Combine data sources to form one unified name history object
            if (externalInfo.data.nameHistory[lang].length > 1) {
                for (let i = 0; i < externalInfo.data.nameHistory[lang].length; i++) {
                    const previous = externalInfo.data.nameHistory[lang][i - 1];
                    const current = externalInfo.data.nameHistory[lang][i];
                    const next = externalInfo.data.nameHistory[lang][i + 1];

                    // Starting year
                    if (!current.from && previous) {
                        current.from = previous.to;
                    }

                    // Ending year
                    if (!current.to && next) {
                        current.to = previous.from;
                    }
                }
            }
        })
        
        return process;
    } else {
        // Check happen through wikidata ID: no ID means no checking
        return process;
    }
}