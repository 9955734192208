import FatalError from './../utils/FatalError';
import capitalizeAllWords from './../utils/capitalizeAllWords';
import removeOptionalLetters from './../utils/removeOptionalLetters';
import transliterateArabic from './../utils/transliterateArabic';

const arabicHierarchy = {
    nederlands: {
        'Algemeen': 'Populair Nederlands',
    },
    engels: {
        'Algemeen': 'Populair Engels',
    },
    frans: {
        'Algemeen': 'Populair Frans',
    },
    duits: {
        'Algemeen': 'Populair Duits',
    },
    aardrijkskunde: {
        'Officieel gebruik (Verenigde Naties)': 'Beirut 2007 System - UNGEGN (United Nations Group of Experts on Geographical Names)',
        'Officieel gebruik (Frankrijk)': 'IGN Système 1973 (Institut Géographique National)',
        'Officieel gebruik (Groot-Brittannië & Verenigde Staten)': 'BGN/PCGN 1956 System - The United States Board on Geographic Names (BGN) & The Permanent Committee on Georaphical Names for British Official Use (PCGN)',
        'Officieel gebruik (Jordanië)': 'RJGC System (Royal Jordanian Geographic Centre)',
        'Officieel gebruik (Egypte & Soedan)': 'Survey of Egypt System', 
    },
    wetenschap: {
        'Wetenschap (Angelsaksisch)': 'The Encyclopaedia of Islam',
        'Wetenschap (Europa)': 'The Encyclopedia of Arabic Language and Linguistics',
        'Wetenschap (Europa & Verenigde Staten)': 'Hans Wehr Transliteration System',
        'Wetenschap (Nederlands, vereenvoudigd)': 'Leemhuis 1982 Vereenvoudigd (uit: Rodinson, Maxime - Mohammed: een biografie)',
    },
    'internationale standaarden': {
        'DMG': 'DMG, Deutsche Morgenländische Gesellschaft (DIN 31635 1982, Deutsches Institut für Normung)',
        'British Standards': 'BS 4280 (The British Standards Institution)',
    },
    documenten: {
        'Internationale Burgerluchtvaartorganisatie': 'Internationale Burgerluchtvaartorganisatie - transliteratie voor Machine Readable Zone op het paspoort',
    },
    bibliotheken: {
        'Verenigde Staten': 'ALA-LC (American Library Association & The Library of Congress)',
    },
    gegevensuitwisseling: {
        'ISO': 'ISO 233: 1984 (Organisation Internationale de Normalisation)',
    },
}

function getArabicTransliterations(process) {
    const letters = removeOptionalLetters(require('../data/decotype2transl/letters.json'));
    const exceptions = removeOptionalLetters(require('../data/decotype2transl/exceptions.json'));
    const positional = removeOptionalLetters(require('../data/decotype2transl/positional.json'));

    // Merge setting tables in when they are applied
    const settings = process.input.settings;
    if (settings.arTaMarbutaConstructState) {
        const constructState = removeOptionalLetters(require('../data/settings/statusconstructus.json'));

        Object.keys(letters).forEach(system => {
            letters[system] = {
                ...letters[system],
                ...constructState[system],
            }
        })
    }

    const addAlEl = removeOptionalLetters(require('../data/settings/addalel.json'));

    Object.keys(addAlEl).forEach(system => {
        Object.keys(addAlEl[system]).forEach(position => {
            positional[system][position] = {
                ...positional[system][position],
                ...addAlEl[system][position],
            }
        })
    })

    if (settings.arAlElAssimilation) {
        const addAssimilation = removeOptionalLetters(require('../data/settings/assimilation.json'));

        Object.keys(addAssimilation).forEach(system => {
            if (system !== 'Internationale Burgerluchtvaartorganisatie - transliteratie voor Machine Readable Zone op het paspoort') {
                Object.keys(addAssimilation[system]).forEach(position => {
                    positional[system][position] = {
                        ...positional[system][position],
                        ...addAssimilation[system][position],
                    }
                })
            }
        })
    }

    // Collect transliterations in proper hierarchy
    const hierarchy = JSON.parse(JSON.stringify(arabicHierarchy));
    
    Object.keys(hierarchy).forEach(collection => {
        Object.keys(hierarchy[collection]).forEach(system => {
            let input = process.data.transliterationBase.ar;

            // Check for ta marbuta
            if (settings.arTaMarbutaConstructState && system !== 'ISO' && system !== 'Internationale Burgerluchtvaartorganisatie') {
                input = input.replace(/ä(\s\w)/g, 'AT$1');
            }

            // Check for word ending setting
            if (settings.arWordEndingH && system !== 'ISO' && system !== 'Internationale Burgerluchtvaartorganisatie') {
                input = input.replace(/(ä)(\s|$)/g, 'a$2');
            }

            // Check for removal of al- el-
            if ((settings.arRemoveAlElForGeo && process.input.userInput.type === 'geo') || (settings.arRemoveAlElForPersons && process.input.userInput.type === 'person')) {
                input = input.replace(/(^|\s)al-/g, '$1');
            }

            const countries = {
                'nederlands': 'nl',
                'engels': 'en',
                'frans': 'fr',
                'duits': 'de',
            }
            let result;

            if (Object.keys(countries).includes(collection) && system === 'Algemeen' && process.data.externalInfo.id && Object.values(process.data.externalInfo.data[countries[collection]]).filter(result => Boolean(result)).length > 0) {
                const options = process.data.externalInfo.data[countries[collection]];
                result = options.wikipedia || options.wikidata;
            } else {
                result = transliterateArabic(input, hierarchy[collection][system], letters, exceptions, positional, process.data.languageTest.determinedLanguage === 'Populair Frans' ? removeOptionalLetters(require('../data/settings/popularfrenchextension.json')) : undefined, true);
            }
            
            // Capitalization changes to result
            if (system === 'Internationale Burgerluchtvaartorganisatie') {
                result = result.toUpperCase();
            } else {
                // Capitalization for every first letter of a word
                result = capitalizeAllWords(result);

                if (!settings.arAlElCapitalized) {
                    // Remove capitalization for lidwoord
                    result = result.replace(/^|\W([AE][ltdrznsşţz̧]{1,2}h{0,1})[- ]/g, s => s.toLowerCase());
                }
            }

            hierarchy[collection][system] = result;
        })
    })
    
    process.data.results = {
        ar: hierarchy,
    }

    return process;
}

function formatCyrillicResults(results, settings, lang) {
    // Mapping for display names of Cyrillic systems
    const systemNames = {
        'de': 'Duits',
        'en': 'Engels',
        'nl': 'Nederlands',
    }

    function flattenObj(obj, parent, res = {}) {
        for (let key in obj) {
            let propName = parent ? parent + '_' + key : key;
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                flattenObj(obj[key], propName, res);
            } else {
                res[propName] = obj[key];
            }
        }
        return res;
    }

    function renameKeys(obj, map) {
        obj = JSON.parse(JSON.stringify(obj));

        // Rename keys to keys in a map object
        Object.keys(obj).forEach((key) => {
            // Get new key value combo
            let newKey = map[key] || key;
            let value = obj[key];

            // Delete the old one
            delete obj[key];

            // Add the new one
            obj[newKey] = value;
        })

        return obj;
    }

    const filterOutKeys = (obj, filterFunc) => {
        obj = JSON.parse(JSON.stringify(obj));

        Object.keys(obj)
            .filter(filterFunc)
            .forEach(toBeRemovedKey => {
                delete obj[toBeRemovedKey];
            })

        return obj;
    }

    let popular = JSON.parse(JSON.stringify(results['populair']));

    // Check whether that is part of the results
    if (popular) {
        // Remove popular to flatten object
        delete results['populair'];
        delete results['officieel'];

        // Flatten popular objects
        Object.keys(popular).forEach(section => {
            popular[section] = flattenObj(popular[section]);

            // Handle setting-specific issues
            if (Object.keys(popular[section]).length > 1) {
                // Only look at settings when there are more than one popular transcription: we need to choose then
                const systems = Object.keys(popular[section]);

                const popularFilter = (input) => {
                    const splitted = input.split('populair');

                    if (splitted.length > 1) {
                        // popularNL, popularEN etc. 
                        return splitted[1].length === 2;
                    } else {
                        return false;
                    }
                }

                if (lang === 'uk' && settings.ukNoSimplification && systems.filter(system => system.includes('NietVersimpeld')).length > 0) {
                    // Remove simplified transcriptions 
                    popular[section] = filterOutKeys(popular[section], popularFilter);
                } else if (((lang === 'uk' && settings.ukTolerantVowelCollision) || (lang === 'ru' && settings.ruTolerantVowelCollision)) && systems.filter(system => system.includes('TolerantKlinkerbotsing')).length > 0) {
                    // Remove simplified transcriptions 
                    popular[section] = filterOutKeys(popular[section], popularFilter);
                }
            }
            
        })

        // Rename keys of popular
        popular = renameKeys(popular, systemNames);

        // Combine popular and all results together again
        results = {...popular, ...results};
    }

    // Make sure all values are string values
    Object.keys(results).forEach((key) => {
        for (const system in results[key]) {
            if (Array.isArray(results[key][system])) {
                results[key][system] = results[key][system][0];
            }
        }
    })

    return results;
}

function getLooseStrings(history, renaming, newName) {
    let looseStrings = {}

    if (history) {
        looseStrings = {
            ...looseStrings,
            ...Object.keys(history).reduce((obj, value, index) => {
                obj[value] = history[index].name;
                return obj;
            }, {})
        }
    }
    
    if (renaming) {
        looseStrings = {
            ...looseStrings,
            ...{renaming: renaming},
        }
    }

    if (newName) {
        looseStrings = {
            ...looseStrings,
            ...{ newName: newName.naam },
        }
    }

    return looseStrings;
}

async function getCyrillicTransliterations(process) {
    const queryEndpoint = {
        uk: 'https://www.taalmannetje.nl/transcriptor/transcriptorUkJSON.php',
        ru: 'https://www.taalmannetje.nl/transcriptor/transcriptorRuJSON.php',
    }

    // Construct POST request to external server for inhouse info
    const externalInfo = process.data.externalInfo;
    const input = process.input;
    const transliterationBase = process.data.transliterationBase;
    const nameHistory = externalInfo.data.nameHistory;
    const oldNames = process.data.inhouseInfo.oldNames;
    const newNames = process.data.inhouseInfo.newNameCrimea ? process.data.inhouseInfo.newNameCrimea.new : {};
    
    const queryTranscriptor = async (base, lang) => {
        let query = {
            losseStrings: getLooseStrings(nameHistory[lang], oldNames[lang], newNames[lang]),
            alles: base,
            query: {
                zoekstring: base,
                land: lang !== 'uk' ? input.userInput.country : 'ua',
                type: input.userInput.type === 'geo' ? input.userInput.type : 'persoon',
                ...(externalInfo.id && { match: externalInfo.id }),
                correctieInvoerToestaan: input.settings[`${lang}AllowCorrections`],
                stringUitNijmegen: process.data.languageTest.languageIsConstructed[lang],
                invoerGebruiker: input.userInput.searchString,
            }
        }
        
        // Get results
        let results;
        const body = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/javascript, */*; q=0.01',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': 'Basic bWFydGlqbjpkYXRzeWItbWl0cWlwLTd4eW1wRQ==',
            },
            mode: 'cors',
            body: 'q=' + JSON.stringify(query)
        }
        
        try {
            results = await (await fetch(sessionStorage.getItem('CORS-proxy') + queryEndpoint[lang], body)).json();
        } catch(e) {
            const backup = sessionStorage.getItem('CORS-proxy');
            sessionStorage.setItem('CORS-proxy', backup);
            results = await (await fetch(backup + queryEndpoint[lang], body)).json();
        }
        
        // Check for renaming info
        if (Object.keys(results['losseStrings']).includes('renaming')) {
            oldNames[lang] = Object.values(results['losseStrings']['renaming'])[0];

            delete results['losseStrings']['renaming'];
        }

        // Check for renaming info
        if (Object.keys(results['losseStrings']).includes('newName')) {
            newNames[lang]['transcription'] = Object.values(results['losseStrings']['newName'])[0];

            delete results['losseStrings']['newName'];
        }

        // Check for name history
        if (Object.keys(results['losseStrings']).length > 0) {
            Object.keys(results['losseStrings']).forEach((index) => {
                nameHistory[lang][index]['transcription'] = Object.values(results['losseStrings'][index])[0]
            })
        }

        // Change transliteration base for displaying in case there's been corrections
        transliterationBase[lang] = results.omgezetteString;

        // Gather setting-specific data from results
        if (lang === 'uk' && results.alles.populair.nl.populairNLNietVersimpeld) {
            process.data.inhouseInfo.simplificationOutputs['simplified'] = results.alles.populair.nl.populairNL;
            process.data.inhouseInfo.simplificationOutputs['nonSimplified'] = results.alles.populair.nl.populairNLNietVersimpeld;
        }
        if ((lang === 'uk' || lang === 'ru') && results.alles.populair.nl.populairNLTolerantKlinkerbotsing) {
            process.data.inhouseInfo.vowelCollisionOutputs[lang] = {
                strict: results.alles.populair.nl.populairNL,
                nonStrict: results.alles.populair.nl.populairNLTolerantKlinkerbotsing,
            }
        }

        // Format results object
        if (Object.keys(results).includes('opmerkingen')) {
            return { ...{ comments: Object.values(results.opmerkingen) }, ...formatCyrillicResults(results.alles, input.settings, lang) };
        } else {
            return formatCyrillicResults(results.alles, input.settings, lang);
        }
    }

    let results;
    if (Object.keys(transliterationBase).length === 1) {
        // Single language
        results = {
            [Object.keys(transliterationBase)[0]]: await queryTranscriptor(transliterationBase[input.userInput.country], input.userInput.country),
        }
    } else {
        // Bilingual: Ukraine
        const ukResults = await queryTranscriptor(transliterationBase['uk'], 'uk');
        const ruResults = await queryTranscriptor(transliterationBase['ru'], 'ru');
        results = {
            uk: ukResults,
            ru: ruResults,
        }
    }

    process.data.results = results;
    return process;
}

export default async function getTransliterations(process) {
    // Generate all possible manual transliterations
    switch (process.input.userInput.lang) {
        case 'ar':
            process = getArabicTransliterations(process);
            break;
        case 'uk':
            process = await getCyrillicTransliterations(process);
            break;
        case 'ru':
            process = await getCyrillicTransliterations(process);
            break;
        default:
            if (process.data.languageTest.determinedLanguage && process.data.languageTest.determinedLanguage.length === 2) {
                process.input.userInput.lang = process.data.languageTest.determinedLanguage;
                return await getTransliterations(process);
            } else {
                throw new FatalError('Helaas kunnen we de taal niet achterhalen. Probeer een invoer met meer informatie en probeer het opnieuw.');
            }
    }
    
    return process;
}