export default function getDataPrototype(userInput, settings) {
    return {
        input: {
            userInput: userInput,
            settings: settings,
            temporarySettings: {
                showFathername: false,
            },
        },
        data: {
            externalInfo: {
                id: undefined,
                desc: undefined,
                wikiTitle: undefined,
                data: {
                    uk: undefined,
                    ru: undefined,
                    ar: undefined,
                    nl: undefined,
                    image: undefined,
                    vocalized: undefined,

                    // Geo names
                    nameHistory: {
                        uk: [],
                        ru: [],
                    },
                    country: undefined,
                    coords: [],
                    koatuu: [],
                    okato: [],
                    oktmo: [],
                    
                    // Persons
                    nationality: [],
                }
            },
            inhouseInfo: {
                isCrimea: undefined,
                newNameCrimea: undefined,
                ukIsLNR: undefined,
                ukIsDNR: undefined,
                ukRenamings2016: undefined,
                taalunieName: undefined,
                simplificationOutputs: {},
                vowelCollisionOutputs: {},
                oldNames: {},
                comments: [],
            },
            languageTest: {
                determinedLanguage: undefined,
                languageValidity: {
                    uk: undefined,
                    ru: undefined,
                    ar: undefined,
                },
                languageIsConstructed: {
                    uk: false,
                    ru: false,
                    ar: false,
                },
            },
            transliterationBase: {},
            results: {},
        },
    }
}