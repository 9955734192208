export default function getProperPersonNameOrder(invoer) {
    const concatenateObj = (obj) => Object.values(obj).join(' ').replace(/ +(?= )/g, '');

    var werknaam = invoer;
    var uitvoer = { voornaam: '', middennamen: '', achternaam: '' };
    if (typeof werknaam === 'undefined') return "";
    if (werknaam.length === 0) return "";

    // Aanname: als er een komma in een naam staat, dan structuur: achternamen, voornaam [overige namen]
    if (werknaam.indexOf(',') > -1) {
        werknaam = werknaam.split(", ");
        // De voornaam is het eerste element na de komma
        werknaam[1] = werknaam[1].split(" ");
        // De middennamen zijn de overige namen
        uitvoer["voornaam"] = werknaam[1][0];
        werknaam[1].shift();
        uitvoer["middennamen"] = werknaam[1].join(' ');
        // Achternaam is het eerste element
        uitvoer["achternaam"] = werknaam[0];
        return concatenateObj(uitvoer);
    }

    // In met name de Oekraïense Wikipedia staan in Oekraïense namen geen komma, maar is de volgorde: achternaam voornaam middennaam
    // Aanname: als de naam uit drie delen bestaat en de laatste naam eindigt op іч, ич of вна, dan gaat het om die structuur
    // Let erop dat het klemtoonteken (́) de sfeer kan bederven. Dat mag er dus niet meer in zitten eigenlijk.
    werknaam = werknaam.split(" ");
    if (werknaam.length === 3) {
        const eindkarakters = ['іч', 'ич', 'вна'];
        if ((eindkarakters.includes(werknaam[2].slice(-2)) || eindkarakters.includes(werknaam[2].slice(-3))) && !(eindkarakters.includes(werknaam[1].slice(-2)) || eindkarakters.includes(werknaam[1].slice(-3)))) {
            // Als het eruitziet of het een Russisch of Oekraïense vadersnaam is
            // Die laatste toevoeging met werknaam[1] is voor namen van het type Людмила Викторовна Ляхович: het laatste lijkt wel een vadersnaam, maar het is een achternaam; in dat geval kijk je ook naar het woord ervoor: als dat een vadersnaam is, dan is het een achternaam
            uitvoer["voornaam"] = werknaam[1];
            uitvoer["middennamen"] = werknaam[2];
            uitvoer["achternaam"] = werknaam[0];
            return concatenateObj(uitvoer);
        } else {
            uitvoer["voornaam"] = werknaam[0];
            uitvoer["middennamen"] = werknaam[1];
            uitvoer["achternaam"] = werknaam[2];
            return concatenateObj(uitvoer);
        }
    }

    // In de overige gevallen gaan we ervan uit: eerste naam is de voornaam, laatste naam is de achternaam, de rest zijn middennamen
    // Dit gaat niet voor alle talen op. Spaanstaligen en Portugeestaligen hebben twee achternamen zonder streepje. 
    // Maar voor Russisch en Oekraïens is dat niet relevant.
    uitvoer = {}
    if (werknaam.length === 1) {
        uitvoer["voornaam"] = werknaam[0];
        return concatenateObj(uitvoer);
    }
    if (werknaam.length === 2) {
        uitvoer["voornaam"] = werknaam[0];
        uitvoer["achternaam"] = werknaam[1];
        return concatenateObj(uitvoer);
    }
    if (werknaam.length > 3) {
        uitvoer["voornaam"] = werknaam[0];
        uitvoer["achternaam"] = werknaam[werknaam.length - 1];
        werknaam.shift(); // Voornaam eraf
        werknaam.pop(); // Achternaam eraf
        uitvoer["middennamen"] = werknaam.join(' ');
        return concatenateObj(uitvoer);
    }
}