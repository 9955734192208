// File imports
import "./style.css";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Typography,
} from "@material-ui/core";
// Package imports
import React, { Component } from "react";

// Utilities
import FatalError from "./utils/FatalError";
// Package component imports
import MuiAlert from "@material-ui/lab/Alert";
// Custom components
import QueryBox from "./components/QueryBox";
import Results from "./components/Results";
import ReverseFlowError from "./utils/ReverseFlowError";
import decotypeLogo from "./images/decotype.png";
import determineTransliterationInput from "./flow/determineTransliterationInput";
// Transcription functions
import getDataPrototype from "./flow/getDataPrototype";
import getInhouseComments from "./flow/getInhouseComments";
import getInhouseInfo from "./flow/getInhouseInfo";
import getLanguageValidity from "./flow/getLanguageValidity";
import getPreferredTranscription from "./utils/getPreferredTranscription";
import getTransliterations from "./flow/getTransliterations";
import getWikidataInfo from "./flow/getWikidataInfo";
import ivdntLogo from "./images/ivdnt.png";
import radboudLogo from "./images/radboud.png";
import taalunieLogo from "./images/taalunie.png";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorDisplayed: false,
      feedbackDisplayed: false,
      feedbackItems: [],
      feedbackCallback: undefined,
      feedbackTitle: "",
      feedbackMessage: "",
      feedbackAlternative: "",
      error: "",
      loading: false,
      loadingText: "",
      process: getDataPrototype({}, {}),
    };

    const initialProxy = "https://web-production-d897.up.railway.app/";
    const backupProxy = "https://cors-anywhere.herokuapp.com/";
    sessionStorage.setItem("CORS-proxy", initialProxy);
    sessionStorage.setItem("backup-proxy", backupProxy);

    this.startTranscription = this.startTranscription.bind(this);
    this.feedbackHandling = this.feedbackHandling.bind(this);
    this.openFeedback = this.openFeedback.bind(this);
    this.listenForEscape = this.listenForEscape.bind(this);
  }

  feedbackHandling(items, title, message, alternative) {
    return new Promise((resolve, reject) => {
      this.openFeedback(items, resolve, title, message, alternative);
    });
  }
  openFeedback(items, resolve, title, message, alternative) {
    this.setState({
      feedbackDisplayed: true,
      feedbackItems: items,
      feedbackCallback: resolve,
      feedbackTitle: title,
      feedbackMessage: message,
      feedbackAlternative: alternative,
    });
  }
  handleFeedback(item) {
    this.state.feedbackCallback(item);
    this.setState({
      feedbackDisplayed: false,
      feedbackItems: [],
      feedbackCallback: undefined,
      feedbackTitle: "",
      feedbackMessage: "",
      feedbackAlternative: "",
    });
  }

  async startTranscription(userInput, settings, historicalProcess = null) {
    this.setState({
      loading: true,
      process: getDataPrototype({}, {}),
    });

    let transcriptionProcess;

    // Only generate process object when there's not historical one
    if (!historicalProcess) {
      // Check whether everything is filled in
      if (
        Object.keys(userInput).filter((key) => userInput[key] === "").length > 0
      ) {
        this.setState({
          loading: false,
          errorDisplayed: true,
          error: "Zorg dat je alle velden hebt ingevuld voordat je verdergaat",
        });

        // Stop execution
        return;
      }

      // Gather basic framework for data processing
      transcriptionProcess = getDataPrototype(
        {
          ...userInput,
          ...{ searchString: userInput.searchString.trim() },
        },
        settings
      );
    } else {
      transcriptionProcess = JSON.parse(JSON.stringify(historicalProcess));
    }

    // Define flow of the application
    const flow = [
      {
        function: getWikidataInfo,
        desc: "Opzoeken in Wikidata ...",
        params: [this.feedbackHandling],
      },
      {
        function: getLanguageValidity,
        desc: "Taal bepalen of verifiëren ...",
        params: [this.feedbackHandling],
      },
      {
        function: getInhouseInfo,
        desc: "Ophalen interne informatie ...",
      },
      {
        function: determineTransliterationInput,
        desc: "Verzamelen vormen die omgezet moeten worden ...",
      },
      {
        function: getTransliterations,
        desc: "Ophalen van de omgezette vormen ...",
      },
      {
        function: getInhouseComments,
        desc: "Aanmaken toelichting voor de gebruiker ...",
      },
    ];

    // Execute flow
    let executedFlow = [];

    // 3 options: start flow at position 1 when wikidata needs to be skipped,
    // start flow at position 4 when there's a historical process that needs to be redone to apply settings
    // or start the whole process from position 0
    let toExecute = flow.slice(
      historicalProcess ? 4 : settings.skipWikidata ? 1 : 0,
      flow.length
    );
    while (toExecute.length !== 0) {
      let step = toExecute[0];

      try {
        this.setState({
          loadingText: step.desc,
        });

        if (Object.keys(step).includes("params")) {
          // Apply parameters
          transcriptionProcess = await step.function(
            transcriptionProcess,
            ...step.params
          );
        } else {
          transcriptionProcess = await step.function(transcriptionProcess);
        }

        // Update flow
        executedFlow.push(toExecute.shift());
      } catch (error) {
        console.log(error);
        if (error instanceof ReverseFlowError) {
          console.log("User is redirecting the flow");
          if (executedFlow.length === 0) {
            this.setState({
              loading: false,
              loadingText: "",
            });

            // Stop process
            toExecute = executedFlow = [];
          } else {
            toExecute.unshift(executedFlow.pop());
          }
        } else if (error instanceof FatalError) {
          this.setState({
            errorDisplayed: true,
            error: error.message,
            loading: false,
            loadingText: "",
          });

          // Stop process
          toExecute = executedFlow = [];
        } else {
          console.log(`Error in ${step.desc.toLowerCase()}`);
          console.error(error);

          // Update flow
          executedFlow.push(toExecute.shift());
        }
      }
    }

    this.setState({
      process: transcriptionProcess,
      loading: false,
      loadingText: "",
    });

    if (
      executedFlow.length > 0 &&
      Object.keys(transcriptionProcess.data.results).length === 0
    ) {
      this.setState({
        errorDisplayed: true,
        error:
          "Helaas zijn er geen resultaten gevonden. Probeer het later opnieuw, of probeer een andere invoer.",
      });
    }

    // Send analytics to database upon finishing
    if (Object.keys(transcriptionProcess.data.results).length > 0) {
      const rawData = {
        "search string": transcriptionProcess.input.userInput.searchString,
        type: transcriptionProcess.input.userInput.type,
        lang: transcriptionProcess.input.userInput.lang,
        country: transcriptionProcess.input.userInput.country,
        "wikidata ID": transcriptionProcess.data.externalInfo.id || "",
        "transcription input": Object.values(
          transcriptionProcess.data.transliterationBase
        ).join(", "),
        "transcription output": getPreferredTranscription(
          transcriptionProcess.data.languageTest.determinedLanguage
            ? transcriptionProcess.data.languageTest.determinedLanguage.includes(
                "Populair"
              )
              ? "ar"
              : transcriptionProcess.data.languageTest.determinedLanguage
            : transcriptionProcess.input.userInput.lang,
          transcriptionProcess
        ),
      };
      const data = new FormData();
      for (const element in rawData) {
        data.append(element, rawData[element]);
      }
      fetch(
        sessionStorage.getItem("CORS-proxy") +
          "https://script.google.com/macros/s/AKfycbxR3IqZx1YAkaPzo0DQp0IE0gTFztjJnsdY7PO6jo-a2pogoiY/exec",
        { method: "POST", body: data }
      );
    }
  }

  listenForEscape(event) {
    if (event.keyCode === 27 && this.state.feedbackDisplayed) {
      // Escape is pressed: execute feedback alternative
      this.handleFeedback(new ReverseFlowError());
    }
  }

  render() {
    // Escape function when there's a dialog
    if (this.state.feedbackDisplayed && this.state.feedbackAlternative) {
      document.addEventListener("keydown", this.listenForEscape, {
        once: true,
      });
    }

    // Snackbar functions
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      this.setState({
        errorDisplayed: false,
      });
    };
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (
      <div
        className="layout-container"
        style={{ overflowX: "hidden", position: "relative" }}
      >
        {/* <div style={{
          'position': 'absolute',
          'inset': 0,
          'textTransform': 'uppercase',
          'display': 'flex',
          'justifyContent': 'center',
          'alignItems': 'center',
          'opacity': '0.1',
          'transform': 'rotate(-45deg)',
          'fontSize': '55px',
          'pointerEvents': 'none',
          'userSelect': 'none',
        }}>Werk in uitvoering</div> */}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <header>
              <a
                href="https://www.ru.nl/clst/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={radboudLogo} alt="Radboud Universiteit Nijmegen" />
              </a>
              <a
                href="https://taalunie.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={taalunieLogo} alt="Nederlandse Taalunie" />
              </a>
              <a
                href="https://ivdnt.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={ivdntLogo} alt="Instituut voor de Nederlandse Taal" />
              </a>
              <a
                href="https://www.decotype.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={decotypeLogo} alt="DecoType" />
              </a>
            </header>
          </Grid>

          <Grid item xs={12} md={6}>
            <QueryBox
              loading={this.state.loading}
              loadingText={this.state.loadingText}
              startTranscription={this.startTranscription}
              process={this.state.process}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Results process={this.state.process} />
          </Grid>
        </Grid>

        <Typography variant="caption" style={{ marginTop: "1em" }}>
          Algemene projectcoördinatie:{" "}
          <a href="mailto:post@nicolinevdsijs.nl">Nicoline van der Sijs</a> (
          <a href="https://ivdnt.org/">Instituut voor de Nederlandse Taal</a>).
          Technische ontwikkeling en ondersteuning Oekraïense en Russische
          transliteraties en transcripties:{" "}
          <a href="https://www.taalmannetje.nl/">Pepijn Hendriks</a>. Met
          medewerking van de arabisten{" "}
          <a href="mailto:jasper.hooghwinkel@hotmail.com">Jasper Hooghwinkel</a>{" "}
          en <a href="mailto:tmilo@decotype.com">Thomas Milo</a>.
          {/* TODO: ADD BACK */}
          {/* Ontwikkeling en ondersteuning Arabische transliteraties en transcripties: <a href="mailto:jasper.hooghwinkel@hotmail.com">Jasper Hooghwinkel</a> en <a href="mailto:tmilo@decotype.com">Thomas Milo</a>.  */}
          <span> </span>Online interface design, technische ontwikkeling en
          dataverzameling: <a href="https://martijn.me">Martijn Schouten</a>.
          Transcripties op basis van machine learning:{" "}
          <a href="mailto:l.tenbosch@let.ru.nl">Louis ten Bosch</a> en{" "}
          <a href="mailto:h.v.d.heuvel@let.ru.nl">Henk van den Heuvel</a> (
          <a href="http://www.ru.nl/clst/">CLST</a>, Radboud Universiteit
          Nijmegen). Met adviezen van Erik Post en Ronald Kon. Met ondersteuning
          van de <a href="https://taalunie.org/">Nederlandse Taalunie</a>.
        </Typography>

        <Snackbar
          open={this.state.errorDisplayed}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {this.state.error}
          </Alert>
        </Snackbar>

        <Dialog
          open={this.state.feedbackDisplayed}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title">
            {this.state.feedbackTitle}
          </DialogTitle>
          {this.state.feedbackMessage && (
            <DialogContent style={{ overflowY: "initial" }}>
              <DialogContentText>
                {this.state.feedbackMessage}
              </DialogContentText>
            </DialogContent>
          )}
          {this.state.feedbackItems.length > 0 && (
            <List style={{ padding: 0 }}>
              {this.state.feedbackItems.map((item) => (
                <ListItem
                  button
                  onClick={() => this.handleFeedback(item.item.value)}
                  key={item.item.value}
                  style={{ padding: "0 24px" }}
                >
                  <ListItemText
                    primary={item.itemLabel.value}
                    secondary={
                      Object.keys(item).includes("itemDescription")
                        ? item.itemDescription.value
                        : ""
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
          <DialogActions
            style={{
              justifyContent: "space-between",
              paddingLeft: "calc(24px - 6px)",
            }}
          >
            <Button
              color="primary"
              onClick={() => this.handleFeedback(new ReverseFlowError())}
            >
              Terug
            </Button>
            {this.state.feedbackAlternative && (
              <Button
                color="primary"
                onClick={() => this.handleFeedback(undefined)}
              >
                {this.state.feedbackAlternative}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default App;
