import FatalError from './../utils/FatalError';
import ReverseFlowError from './../utils/ReverseFlowError';
import checkLanguageValidity from './../utils/checkLanguageValidity';
import getNijmegen from './../utils/getNijmegen';

async function determineLanguage(searchString) {
    // Nijmegen language test
    const determinedLanguages = await getNijmegen(searchString, 'lat2ru_uk_classif');
    
    if (determinedLanguages) {
        // Classification in the form of: input[\t]classification[\n]
        const results = determinedLanguages.toLowerCase();

        return results.includes('unknown') ? undefined : results.trim();           
    }
}

async function getDefiniteLanguage(validity, input, externalInfo) {
    switch (input.country) {
        case 'ru':
            if (!validity.ru && validity.uk) {
                throw new FatalError('De invoer is geen geldig Russisch, maar Oekraïens. Verifieer de invoer en probeer het opnieuw.');
            } else if (validity.ar) {
                return 'ar';
            } else {
                return 'ru';
            }
        
        case 'uk': 
            if (externalInfo.id && (externalInfo.data.uk.wikidata || externalInfo.data.ru.wikidata)) {
                return externalInfo.data.uk.wikidata ? 'uk' : 'ru';
            } else if (validity.ru && validity.uk) {
                // eslint-disable-next-line no-throw-literal
                throw {
                    message: 'De cyrillische invoer kan zowel Oekraïens als Russisch zijn. Om welke taal gaat het hier?',
                    feedbackItems: [{ item: { value: 'ru' }, itemLabel: { value: 'Russisch' } }, { item: { value: 'uk' }, itemLabel: { value: 'Oekraïens' }}],
                }
            } else if (validity.ru || validity.uk) {
                const guessedLang = validity.ru ? 'ru' : 'uk';

                // eslint-disable-next-line no-throw-literal
                throw {
                    message: `De cyrillische invoer kan alleen ${guessedLang === 'uk' ? 'Oekraïens' : 'Russisch'} zijn.`,
                    feedbackItems: [{ item: { value: guessedLang }, itemLabel: { value: `Zet om als ${guessedLang === 'uk' ? 'Oekraïens' : 'Russisch'}` } }],
                    allowAlternative: false,
                }
            } else {
                // Not Russian nor Ukrainian, therefore should request language test from Nijmegen
                // eslint-disable-next-line no-throw-literal
                throw {
                    message: 'Met welke taal hebben we te maken?',
                    feedbackItems: [{ item: { value: 'ru' }, itemLabel: { value: 'Russisch' } }, { item: { value: 'uk' }, itemLabel: { value: 'Oekraïens' } }],
                }
            }

        default:
            // Arabic country
            if (validity.ar) {
                return 'ar';
            } else {
                // Because we are unable to use Nijmegen to get our Arabic, we need to stop the process
                // eslint-disable-next-line no-throw-literal
                throw {
                    message: 'In welke populaire schrijfwijze is de invoer? Let hierbij bijvoorbeeld op de bron waar de naam uit komt.',
                    feedbackItems: [{ item: { value: 'Populair Nederlands' }, itemLabel: { value: 'Nederlands' } }, { item: { value: 'Populair Frans' }, itemLabel: { value: 'Frans' } }, { item: { value: 'Populair Engels' }, itemLabel: { value: 'Engels' } }, { item: { value: 'Populair Duits' }, itemLabel: { value: 'Duits' } }],
                }
            }
    }
}

export default async function getLanguageValidity(process, itemSelection) {
    // Indicate whether input is correct
    Object.keys(process.data.languageTest.languageValidity).forEach(lang => {
        process.data.languageTest.languageValidity[lang] = checkLanguageValidity(process.input.userInput.searchString, lang);
    })

    if (process.input.userInput.lang === 'unknown' && process.data.externalInfo.id) {
        // Try to determine the language from wikidata
        const presentLanguages = ['ar', 'uk', 'ru'].filter(lang => Object.values(process.data.externalInfo.data[lang]).filter(result => Boolean(result)).length > 0);

        // Only automatically determine when there's just one option
        if (presentLanguages.length === 1) {
            process.input.userInput.lang = presentLanguages[0];
        } else {
            let feedbackItems = [{ item: { value: 'ru' }, itemLabel: { value: 'Russisch' } }, { item: { value: 'uk' }, itemLabel: { value: 'Oekraïens' } }, { item: { value: 'ar' }, itemLabel: { value: 'Arabisch' } }].filter(item => presentLanguages.includes(item.item.value));
            process.input.userInput.lang = await itemSelection(feedbackItems, 'Selecteer een optie', 'Helaas hebben we de taal niet automatisch kunnen vaststellen. Achterhaal de taal en kies hier de juiste, of pas je invoer aan.', null);
        }
    }

    // Confirm language if there's no Wikidata ID
    if (!process.data.externalInfo.id || process.input.userInput.lang === 'unknown' || !(process.data.externalInfo.data[process.input.userInput.lang].wikidata || process.data.externalInfo.data[process.input.userInput.lang].wikipedia)) {
        if (process.data.externalInfo.id && process.input.userInput.lang === 'ar') {
            // Check whether there is an input in another language and assign determined language immediately to that language
            const relatedLang = {
                nl: 'Populair Nederlands',
                fr: 'Populair Frans',
                en: 'Populair Engels',
                de: 'Populair Duits',
            }

            Object.keys(relatedLang).forEach(lang => {
                if (!process.data.languageTest.determinedLanguage && process.data.externalInfo.data[lang] && (process.data.externalInfo.data[lang].wikipedia || process.data.externalInfo.data[lang].wikidata)) {
                    process.data.languageTest.determinedLanguage = relatedLang[lang];
                }
            })
        } 
        
        if (!process.data.languageTest.determinedLanguage) { 
            try {
                process.data.languageTest.determinedLanguage = await getDefiniteLanguage(process.data.languageTest.languageValidity, process.input.userInput, process.data.externalInfo);
            } catch (error) {
                if (error.feedbackItems) {
                    // There are feedback items that need to be selected
                    const selectedLanguage = await itemSelection(error.feedbackItems, 'Selecteer een optie', error.message, error.allowAlternative !== false ? 'Ik weet het niet zeker, help mij' : null);

                    // Get either user selected language or let Nijmegen decide
                    if (selectedLanguage instanceof ReverseFlowError) {
                        throw new ReverseFlowError();
                    } else if (selectedLanguage) {
                        process.data.languageTest.determinedLanguage = selectedLanguage;
                    } else if (process.input.userInput.lang !== 'ar') {
                        const determinedLanguage = await determineLanguage(process.input.userInput.searchString);

                        // Always confirm the determined language
                        let feedbackItems;
                        if (determinedLanguage) {
                            feedbackItems = [{ item: { value: determinedLanguage }, itemLabel: { value: 'Dit klopt, ga verder' } }, { item: { value: determinedLanguage === 'ru' ? 'uk' : 'ru' }, itemLabel: { value: `Nee, kies toch voor het ${determinedLanguage === 'ru' ? 'Oekraïens' : 'Russisch'}` } }];
                        } else {
                            feedbackItems = [{ item: { value: 'ru' }, itemLabel: { value: 'Russisch' } }, { item: { value: 'uk' }, itemLabel: { value: 'Oekraïens' } }];
                        }
                        let confirmedLanguage = await itemSelection(feedbackItems, 'Selecteer een optie', determinedLanguage ? `Het gaat waarschijnlijk om ${determinedLanguage === 'ru' ? 'Russisch' : 'Oekraïens'}.` : 'Helaas hebben we de taal niet kunnen vaststellen. Achterhaal de taal en kies hier de juiste, of pas je invoer aan.', null);

                        if (confirmedLanguage instanceof ReverseFlowError) {
                            throw new ReverseFlowError();
                        } else if (confirmedLanguage) {
                            // Nijmegen found a language
                            process.data.languageTest.determinedLanguage = confirmedLanguage;
                        } else {
                            // Cancel process: user needs to confirm language, otherwise don't continue
                            throw new FatalError('Aan de hand van de invoer en de bekende informatie kunnen we helaas geen Nederlandse schrijfwijze bepalen. Probeer het opnieuw met aanvullende of andere gegevens, liefst in het oorspronkelijke schrift.');
                        }
                    } else {
                        // Arabic language fallback
                        const input = process.input.userInput.searchString.toLowerCase();

                        // Last case fallbacks when there is no system available whatshoever
                        if (input.includes('oe') || input.includes('sj')) {
                            process.data.languageTest.determinedLanguage = 'Populair Nederlands';
                        } else if (input.includes('ou')) {
                            process.data.languageTest.determinedLanguage = 'Populair Frans';
                        } else if (input.includes('sch')) {
                            process.data.languageTest.determinedLanguage = 'Populair Duits';
                        } else {
                            // Fallback option, 'ee' as indicator for English
                            process.data.languageTest.determinedLanguage = 'Populair Engels';
                        }
                    }
                } else {
                    throw error;
                }
            }
        }
    }

    return process;
}
