import ReverseFlowError from './../utils/ReverseFlowError';
import getProperPersonNameOrder from './../utils/getProperPersonNameOrder';

async function findWikidataId(userInput) {
    const countryCodes = {
        'uk': `{wd:Q212${userInput.type === 'person' ? ' wd:Q15180 wd:Q34266' : ''}}`,
        'ru': '{wd:Q159 wd:Q15180 wd:Q34266}',
        'ma': '{wd:Q1028 wd:Q6250}',
        'dz': '{wd:Q262}',
        'tn': '{wd:Q948}',
        'ly': '{wd:Q1016}',
        'eg': '{wd:Q79}',
        'sd': '{wd:Q1049}',
        'ps': '{wd:Q23792 wd:Q42620 wd:Q219060}',
        'lb': '{wd:Q822}',
        'sy': '{wd:Q858}',
        'iq': '{wd:Q796 wd:Q149805 wd:Q3041595 wd:Q3108185 wd:Q149805}',
        'kw': '{wd:Q817}',
        'jo': '{wd:Q810}',
        'sa': '{wd:Q851}',
        'ye': '{wd:Q805}',
        'ae': '{wd:Q878}',
        'om': '{wd:Q842}',
        'bh': '{wd:Q398}',
        'qa': '{wd:Q846}',
        'mr': '{wd:Q1025}',
        // TODO: ADD THIS BACK
        // 'unknown': `{wd:Q159 wd:Q212 wd:Q1028 wd:Q6250 wd:Q262 wd:Q948 wd:Q1016 wd:Q79 wd:Q1049 wd:Q23792 wd:Q42620 wd:Q219060 wd:Q822 wd:Q858 wd:Q796 wd:Q817 wd:Q810 wd:Q851 wd:Q805 wd:Q878 wd:Q842 wd:Q398 wd:Q846 wd:Q1025 wd:Q149805 wd:Q3041595 wd:Q3108185 wd:Q149805}`,
        // TODO: REMOVE THIS ONE
        'unknown': `{wd:Q159 wd:Q212 wd:Q15180 wd:Q34266}`,
    }

    const searchEntities = {
        'geo': '{wd:Q486972 wd:Q863944 wd:Q15324 wd:Q271669}',
        'person': '{wd:Q5 wd:Q39201}',
        'misc': '{wd:Q5 wd:Q486972 wd:Q1496967 wd:Q863944 wd:Q15324 wd:Q271669}'
    }

    // const query = () => { return `
    //     SELECT DISTINCT ?item ?itemLabel ?itemDescription WHERE {
    //         SERVICE wikibase:mwapi {
    //             bd:serviceParam wikibase:api "Search" .
    //             bd:serviceParam wikibase:endpoint "www.wikidata.org" .
    //             bd:serviceParam mwapi:srsearch "${userInput.searchString}" .
    //             ?item wikibase:apiOutputItem mwapi:title .
    //         }
    //         VALUES ?claims ${searchEntities[userInput.type]}
    //         VALUES ?landen ${countryCodes[userInput.country]}
    //         ?item wdt:P31*/wdt:P279* ?claims .
    //         ?item (wdt:P17|wdt:P27) ?landen .

    //         SERVICE wikibase:label { bd:serviceParam wikibase:language "nl,en,de,uk,ru,ar". }
    //     } GROUP BY ?item ?itemLabel ?itemDescription  LIMIT 25
    // `}

    const query = () => { 
        return userInput.type === 'geo' ? `
            SELECT DISTINCT ?item ?itemLabel ?itemDescription WHERE {
            SERVICE wikibase:mwapi {
                bd:serviceParam wikibase:api "EntitySearch" .
                bd:serviceParam wikibase:endpoint "www.wikidata.org" .
                bd:serviceParam mwapi:search "${userInput.searchString}" .
                bd:serviceParam mwapi:language "en" .
                bd:serviceParam wikibase:limit 500 .
                ?item wikibase:apiOutputItem mwapi:item .
                ?ordinal wikibase:apiOrdinal true .
            }
            VALUES ?claims ${searchEntities[userInput.type]}
            VALUES ?landen ${countryCodes[userInput.country]}
            ?item (wdt:P131*|wdt:P17|wdt:P27) ?landen.
            hint:Prior hint:gearing "forward".
            ?item wdt:P31/wdt:P279* ?claims.
            hint:Prior hint:gearing "forward".
            SERVICE wikibase:label { bd:serviceParam wikibase:language "nl,en,de,uk,ru,ar". }
            } ORDER BY ASC (?ordinal) LIMIT 25
        ` : `
            SELECT DISTINCT ?item ?itemLabel ?itemDescription WHERE {
            SERVICE wikibase:mwapi {
                bd:serviceParam wikibase:api "Search" .
                bd:serviceParam wikibase:endpoint "www.wikidata.org" .
                bd:serviceParam mwapi:srsearch "${userInput.searchString}" .
                bd:serviceParam wikibase:limit 500 .
                ?item wikibase:apiOutputItem mwapi:title .
                ?ordinal wikibase:apiOrdinal true .
            }
            VALUES ?claims ${searchEntities[userInput.type]}
            VALUES ?landen ${countryCodes[userInput.country]}
            ?item (wdt:P27) ?landen .
            hint:Prior hint:gearing "forward" .
            ?item wdt:P31/wdt:P279* ?claims .
            hint:Prior hint:gearing "forward" .
            SERVICE wikibase:label { bd:serviceParam wikibase:language "nl,en,de,uk,ru,ar". }
            } ORDER BY ASC (?ordinal)  LIMIT 25
        `
    }

    // Initialize SPARQL query endpoints
    const wbk = require('wikibase-sdk')({
        instance: 'https://www.wikidata.org',
        sparqlEndpoint: 'https://query.wikidata.org/sparql'
    })

    // Get data
    async function getSparqlData(query) {
        try {
            const response = await fetch(query);
            const results = await response.json();
            return results.results.bindings
        }
        catch (err) {
            return undefined
        }
    }

    async function getSingularId() {
        try {
            let results = await getSparqlData(wbk.sparqlQuery(query()));

            // Check whether the results aren't undefined, otherwise quit execution
            if (results === undefined) {
                return results;
            } else {
                return results;
            }
        } catch (err) {
            return undefined;
        }
    }

    return getSingularId();
}

// Function to get the wikidata ID from the URI value
function extractIdFromUri(uri) {
    let wdId = uri.split('/');
    return wdId[wdId.length - 1];
}

// Request labels and claims function
async function getAPIInfo(wdId) {
    const wdEndpoint = 'https://www.wikidata.org/w/api.php';
    const wdClaimsEndpoint = wdEndpoint + '?origin=*&action=wbgetentities&format=json&utf8=1&ids=';

    return await fetch(wdClaimsEndpoint + wdId);
}

// Function to format name history
function getNameHistory(claims) {
    const historyObj = claims.P1448;
    let history = {
        uk: [],
        ru: [],
    }

    if (historyObj) {
        // Take each of the old names
        historyObj.forEach(name => {
            const data = name.mainsnak.datavalue.value;

            if (Object.keys(history).includes(data.language)) {
                // Only add when there's a start or end date
                if (name.qualifiers) {
                    history[data.language].push({
                        name: data.text,
                    })

                    // Gather from and to dates
                    Object.keys(name.qualifiers).forEach(qualifier => {
                        let dataVal = name.qualifiers[qualifier][0].datavalue;

                        // P580 is from, P582 is to
                        if (dataVal && (qualifier === 'P580' || qualifier === 'P582')) {
                            history[data.language][Object.keys(history[data.language]).length - 1][qualifier === 'P580' ? 'from' : 'to'] = dataVal.value.time.substring(1, 5);
                        }
                    })
                }
            }
        })

        // Sort dates
        Object.keys(history).forEach(lang => {
            // Make sure that each entry at least has a start or end date
            history[lang] = history[lang].filter(element => element.from || element.to);
        })

        return history;
    } else {
        return {};
    }
}

export default function getWikidataInfo(process, itemSelection) {
    return new Promise((resolve, reject) => {
        // Find Wikidata data
        async function gatherResults() {
            let wdItems = await findWikidataId(process.input.userInput);

            if (wdItems) {
                // Filter out only elements that have the wikidata ID as title
                wdItems = wdItems.filter(item => {
                    return item.itemLabel.value.search(/Q\d{1,}/g);
                })
            }

            if (process.input.userInput.lang === 'ar' && (wdItems === undefined || wdItems.length === 0)) {
                const query = process.input.userInput.searchString;

                if (query.indexOf(' ') >= 0) {
                    // For Arabic, spaces might intervene in search results
                    wdItems = await findWikidataId({
                        ...process.input.userInput,
                        ...{searchString: query.replace(' ', '')}
                    });
                }
            }
            
            // Stop execution if no wikidata ID is found
            if (wdItems === undefined || wdItems.length === 0) {
                const userFeedback = await itemSelection(wdItems, 'Zoekresultaten', 'We konden de ingegeven naam niet identificeren. Probeer opnieuw of ga door met de invoer.', 'Doorgaan met omzetten');
                
                if (userFeedback instanceof ReverseFlowError) {
                    reject(userFeedback);
                    return;
                } else if (!userFeedback) {
                    // User has not selected an option: cancel search, continue with process
                    resolve(process);
                    return;
                }
            }

            // Give end user option to select the right wikidata object
            const multipleHits = wdItems.length > 1;
            const selectedWdItem = await itemSelection(wdItems, 'Zoekresultaten', '', `${multipleHits ? 'Geen van bovenstaande' : 'Niet het bovenstaande'}. Zet de invoer om`);
            
            if (selectedWdItem instanceof ReverseFlowError) {
                reject(selectedWdItem);
                return;
            } else if (!selectedWdItem) {
                // User has not selected an option: cancel search, continue with process
                resolve(process);
                return;
            }

            const wdId = extractIdFromUri(selectedWdItem);
        
            // Get actual info from wikidata ID
            getAPIInfo(wdId).then(res => res.json())
                .then(function (data) {
                    const entries = data.entities[wdId];
                    let wikiTitle = undefined;
                    
                    // Data collection helper functions
                    const getClaimValue = (claimId, index = 0) => {
                        try {
                            let claim = entries.claims[claimId];
                            return claim[index === -1 ? claim.length - 1 : index].mainsnak.datavalue.value;
                        } catch (e) {
                            return undefined;
                        }
                    };

                    const getLabel = (key) => {
                        let wikipediaLabel = undefined;
                        let wikidataLabel = entries.labels[key] ? entries.labels[key].value : undefined;

                        if (entries.sitelinks[key + 'wiki']) {
                            wikipediaLabel = entries.sitelinks[key + 'wiki'].title;

                            if (key === 'nl') {
                                wikiTitle = wikipediaLabel;
                            }

                            // Remove content in brackets
                            wikipediaLabel = wikipediaLabel.replace(/\(.+?\)/g, '');
                            
                            wikipediaLabel = wikipediaLabel.trim();
                        }

                        if (process.input.userInput.type === 'person' && process.input.userInput.lang !== 'ar') {
                            wikipediaLabel = wikipediaLabel ? getProperPersonNameOrder(wikipediaLabel) : undefined;
                            wikidataLabel = wikidataLabel ? getProperPersonNameOrder(wikidataLabel) : undefined;
                        }

                        return {
                            wikidata: wikidataLabel,
                            wikipedia: wikipediaLabel,
                        }
                    } 

                    // Gather all wanted data
                    (async () => {
                        process.data.externalInfo = {
                            id: wdId,
                            desc: entries.descriptions.nl ? entries.descriptions.nl.value : undefined,
                            data: {
                                uk: getLabel('uk'),
                                ru: getLabel('ru'),
                                ar: getLabel('ar'),
                                nl: getLabel('nl'),
                                de: getLabel('de'),
                                fr: getLabel('fr'),
                                en: getLabel('en'),
                                vocalized: getClaimValue('P4239'),
                                image: getClaimValue('P18') !== undefined ? 'https://commons.wikimedia.org/wiki/Special:FilePath/' + getClaimValue('P18').replace(/\s/g, '_') + '?width=200px' : undefined,

                                // Geo names
                                nameHistory: getNameHistory(entries.claims),
                                coords: getClaimValue('P625'),
                                koatuu: getClaimValue('P1077'),
                                okato: getClaimValue('P721'),
                                oktmo: getClaimValue('P764'),
                            },
                            wikiTitle: wikiTitle,
                        }

                        resolve(process);
                    })()
                })
        }

        gatherResults();
    })
}
