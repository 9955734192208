export default function removeOptionalLetters(json) {
    json = JSON.parse(JSON.stringify((json)));
    
    for (const system in json) {
        if (system.includes('Populair')) {
            // Populair has different input options that we need to remove
            for (const subObj in json[system]) {
                if (typeof json[system][subObj] === 'object' && json[system][subObj] !== null) {
                    for (const position in json[system][subObj]) {
                        json[system][subObj][position] = json[system][subObj][position].split(' - ')[0];
                    }
                } else {
                    json[system][subObj] = json[system][subObj].split(' - ')[0];
                }
            }
        }
    }

    return json;
}