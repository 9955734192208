import FatalError from './FatalError';
import distance from 'jaro-winkler';
import removeDiacritics from './removeDiacritics';
import { v4 as uuidv4 } from 'uuid';

function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

// Generic function for starting requests
async function doRequest(url, type, file = null) { 
    const reqUrl = 'https://webservices.cls.ru.nl/t2t/' + url;
    let body = {
        method: type.toUpperCase(),
        mode: 'cors',
        headers: {
            'Authorization': 'Basic RTE5NjExMjY6YUI5MHdoKiZPcDIzMG1nNkYmb0Q=',
        }
    }

    if (file) {
        const formData = new FormData();
        formData.append('file', file, 'input.ar.txt');

        body = {
            ...body,
            ...{
                body: formData,
            }
        }
    }
    
    let response;
    try {
        response = await fetch(sessionStorage.getItem('CORS-proxy') + reqUrl, body);
    } catch (e) {
        const backup = sessionStorage.getItem('backup-proxy');
        sessionStorage.setItem('CORS-proxy', backup);
        response = await fetch(backup + reqUrl, body);
    }

    return response;
}

async function processResult(id, transformOutput, targetFile, inputString, searchString, task) {
    const status = await (await doRequest(`${id}/`, 'get')).text();

    // Check whether process is complete
    if (status.includes('code="2"')) {
        // Step 5: gather results
        const results = await (await (doRequest(`${id}/output/${targetFile}`, 'get'))).text();

        let finalResult = results;
        if ((transformOutput && targetFile === 'webrequest.list') || task === 'lat2ru_uk_classif') {
            finalResult = finalResult.split(/[\n\t]/)       // Split the results into a new array
                .filter(str => str)                     // Remove all empty strings in the array
                .filter((el, index) => index % 2 !== 0) // Only keep all the even indices in the array: those are the translated elements
                .join(' ');                             // Cast to a single string
        } else {
            // Decotype transformation
            const allWords = [];
            const splittedInput = inputString.split(' ');
            const splittedSearch = searchString ? searchString.trim().replace(/\s{2,}/g, ' ').replace(/(?:^|\W)((?:A|E|a|e)(?:l|t|d|r|z|s|n))( )/g, 'al-').replace(/ {0,}- {0,}/g, '-').split(' ') : null;
            
            for (let i = 0; i < splittedInput.length; i++) {
                // Count amount of varieties for this word
                // eslint-disable-next-line no-useless-escape
                const variantRegex = new RegExp(`word \\(${i}\\) decotype \\(\\d\\): (.+)`, 'g');
                const variantCount = [...results.matchAll(variantRegex)].map(wordMatch => wordMatch[1].replace(/\s/g, ''));

                if (variantCount.length === 0) {
                    throw new FatalError('Helaas zijn er voor een deel van jouw input geen transliteraties gevonden. Verander je invoer en probeer het opnieuw');
                } else if (variantCount.length === 1) {
                    allWords.push(variantCount[0]);
                } else {
                    if (splittedSearch) {
                        // Select reference word
                        let referenceWord;

                        if (i > splittedSearch.length - 1) {
                            referenceWord = splittedSearch[splittedSearch.length - 1];
                        } else {
                            referenceWord = splittedSearch[i];
                        }

                        // Strip reference word of all diacritics and lowercase
                        referenceWord = removeDiacritics(referenceWord.toLowerCase());

                        // Change combinations that are changed in DMG+ in input string
                        const transformTable = {
                            ei: 'ay',
                            eï: 'ay',
                            ai: 'ay',
                            aï: 'ay',
                            ey: 'ay',
                            ia: 'iya',
                            oe: 'u',
                            o: 'u',
                            dsch: 'j',
                            sch: 'sh',
                            sjk: 'shq',
                            dj: 'j',
                            sj: 'sh',
                            aou: 'aw',
                            oua: 'wa',
                        }
                        Object.keys(transformTable).forEach(transformPattern => {
                            // Make pattern to replace
                            let regex = new RegExp(transformPattern, 'gm');

                            // Replace pattern with uppercase version to make sure transformations are final
                            referenceWord = referenceWord.replace(regex, transformTable[transformPattern].toUpperCase());
                        })

                        // Recast to lowercase version
                        referenceWord = referenceWord.toLowerCase();
                        
                        // Same transformations for all variants
                        const representations = variantCount.map(variant => removeDiacritics(variant.toLowerCase()).replace(/:/g, ''));

                        // Get the best matching option
                        const removeArticles = word => word.replace(/(?:^|\W)(A|E|a|e)l(-| )/g, '').trim();
                        const reprScores = representations.map(variant => distance(removeArticles(referenceWord), removeArticles(variant)));
                        const indexOfMaxValue = reprScores.reduce((iMax, x, i, arr) => x > arr[iMax] ? i : iMax, 0);

                        allWords.push(variantCount[indexOfMaxValue]);
                    } else {
                        throw new FatalError('Helaas zijn er voor een deel van jouw input geen transliteraties gevonden. Verander je invoer en probeer het opnieuw');
                    }
                }
            }
            
            finalResult = allWords.join(' ');
        }
        
        // Step 6: delete the project
        await doRequest(`${id}/`, 'delete');

        return finalResult;
    } else {
        return undefined;
    }
}

export default async function getNijmegen(inputString, task, file = null, searchString = null) {
    // Input must be seperatable by spaces
    const inputArr = inputString.split(' ');

    // Get UUID to reference to requests
    // eslint-disable-next-line no-useless-escape
    const id = uuidv4().replace(/\-/g, '');
    
    try {
        // Step 1: creating a new project
        const initialRequest = await doRequest(`${id}/`, 'put');

        // Check whether service is available
        if (initialRequest.status !== 201) {
            throw new FatalError('Nijmegen is niet beschikbaar');
        }

        // Step 2: start transliteration process by sending the input
        if (!file) {
            await doRequest(`${id}/input/webrequest?inputtemplate=wordlist&contents=${task === 'lat2ru_uk_classif' ? inputString : inputArr.join('%0D%0A')}`, 'post');
        } else {
            await doRequest(`${id}/input/input.ar.txt?inputtemplate=wordlist`, 'post', file);
        }
        
        // Step 3: start process of transliteration
        await doRequest(`${id}/?task=${task}&n=1`, 'post');
        
        // Step 4: check whether results are available, otherwise retry
        const transformOutput = !(task === 'lat2ru_uk_classif');
        const targetFile = file ? 'input.ar.list' : 'webrequest.list'
        let result = await processResult(id, transformOutput, targetFile, inputString, searchString, task);
        
        while (!result) {
            // Continuously keep checking whether there are results
            await delay(500);

            // Try again
            result = await processResult(id, transformOutput, targetFile, inputString, searchString, task);
        }

        return result;
    } catch (e) {
        console.log('Something went wrong while retrieving information from CLST')
        console.error(e);

        throw new FatalError('Helaas is het op dit moment niet mogelijk om transcripties te genereren voor onbekende entiteiten. Probeer het later opnieuw.');
    }
}